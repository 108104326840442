const init = {
};

export const About = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_ABOUT":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
