import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Text, Popup, BouncerContextHook } from "@StixNext/mojito-ui";
import * as billingActions from "../../../actions/Billing";
import * as transActions from "../../../actions/Transaction";
import * as actions from "../../../actions/Login";
import * as prodActions from "../../../actions/Products";
import ActiveDot from "../../../assets/images/icons/active_green_dot.svg";
import ExpiredDot from "../../../assets/images/icons/expired.svg";
import Pendingdot from "../../../assets/images/icons/pending_dot.svg";
import InActiveDot from "../../../assets/images/icons/livestream_dot.svg";
import decode from "decode-html";
import ReactHtmlParser from "react-html-parser";
import Moment from "react-moment";
import moment from "moment";
import { pushEventToData } from "../../../utils/Datalayer";

function PassSubscriptions(props) {
  const { history, location = {} } = props.loginDetails;
  const { pathname = "/" } = location;
  const [pageNo, setPageNo] = useState("1");
  const [toggleActive, setToggleActive] = useState(false);
  const [pendingSubscription, setPendingSubscription] = useState([]);
  const [notValidRequest, setNotValidRequest] = useState(false);

  const value = BouncerContextHook();

  useEffect(() => {
    props.fetchProducts({}, "", false);
  }, []);
  useEffect(() => {
    if (pathname.includes("autorenew")) {
      let orderid = props.queryparams.params.orderid;
      let renewState =
        props.queryparams.params.renewState === "true" ? true : false;
      if (
        props.transactionDetails.userPasses.length > 0 &&
        props.transactionDetails.fetchtransactiondone
      ) {
        let filterRenewPass = false;
        filterRenewPass = props?.transactionDetails?.userPasses?.filter(
          (item) => {
            return item.id === orderid;
          }
        );
        let isAllowToRenew = null;
        isAllowToRenew = renewState
          ? filterRenewPass[0]?.subscriptionId
            ? true
            : false
          : filterRenewPass[0]?.subscriptionId
          ? false
          : true;
        if (
          Array.isArray(filterRenewPass) &&
          filterRenewPass.length > 0 &&
          isAllowToRenew
        ) {
          handleAutoRenewToggleClick(
            renewState,
            filterRenewPass[0].subscriptionId,
            filterRenewPass[0].product,
            filterRenewPass[0]
          );
        } else if (
          Array.isArray(filterRenewPass) &&
          isAllowToRenew === false &&
          props.transactionDetails.renewalDone
        ) {
          history.push("/myaccount");
        } else if (
          Array.isArray(filterRenewPass) &&
          isAllowToRenew === false &&
          !props.transactionDetails.renewalDone
        ) {
          props.changeTransactionValues(
            "nonValidMessage",
            renewState
              ? "There is no active subscription to cancel"
              : "There is already active subscription"
          );
          props.changeTransactionValues("transactionStatus", 15);
          props.changeTransactionValues("isOpen", true);
        } else if (
          Array.isArray(filterRenewPass) &&
          filterRenewPass.length === 0
        ) {
          props.changeTransactionValues(
            "nonValidMessage",
            "Invalid renewal request"
          );
          props.changeTransactionValues("transactionStatus", 15);
          props.changeTransactionValues("isOpen", true);
        }
      }
    }
  }, [
    props.transactionDetails.userPasses,
    props.transactionDetails.fetchtransactiondone,
  ]);

  useEffect(() => {
    if (props.transactionDetails.recurringData.length > 0) {
      let pendingPasses = props.transactionDetails.recurringData.filter(
        (pass) => {
          return pass?.subscriptionresdata?.status === "Pending";
        }
      );
      setPendingSubscription(pendingPasses);
      let activePaymentMethods = props.transactionDetails.recurringData.filter(
        (pass) => {
          return (
            pass?.subscriptionresdata?.status === "Pending" ||
            pass?.subscriptionresdata?.status === "Active"
          );
        }
      );
      props.changeBillingValues("paymentMethods", activePaymentMethods);
    }
  }, [props.transactionDetails.recurringData]);

  const handleAutoRenewToggleClick = (
    checked,
    subscriptionId,
    product,
    pass
  ) => {
    product = {
      ...product,
      isFixedExpiryDate: product.isFixedExpiryDate === "true",
      isActive: product.isActive === "true",
      isSeasonPass: product?.isSeasonPass === "true",
      isFreeEvent: product.isFreeEvent === "true",
      isSubscribableEvent: product.isSubscribableEvent === "true",
      isPublished: product.isPublished === "true",
      isAutoRenew: product.isAutoRenew,
    };
    props.changeBillingValues("currentPass", pass);
    if (checked) {
      props.changeBillingValues("subscriptionId", subscriptionId);
      props.changeBillingValues("cancellingAutoRenewSubscription", product);
      props.changeBillingValues("showCancelAutoRenewSubscriptionPopup", true);
    } else {
      if (props.billingDetails.paymentMethods?.length > 0) {
        props.changeBillingValues("autoRenewSubscription", product);
        props.changeBillingValues("showStartAutoRenewSubscriptionPopup", true);
      } else {
        props.changeBillingValues("autoRenewSubscription", product);
        props.changeBillingValues("showNoPaymentMethodStoredPopup", true);
      }
    }
  };

  // Cancel AutoRenew Subscription - Proceed Button Clicked
  const handleProceedCancelAutoRenewOnClick = () => {
    const subscription = props.billingDetails.cancellingAutoRenewSubscription;
    const subscribedProductsList = props.billingDetails.subscribedProductsList;

    const updatedProductsList = subscribedProductsList.map((product) => {
      if (product.id === subscription.id) product.isAutoRenew = false;
      return product;
    });
    props.changeBillingValues("subscribedProductsList", updatedProductsList);
    props.changeBillingValues("cancellingAutoRenewSubscription", {});
    props.changeBillingValues("showCancelAutoRenewSubscriptionPopup", false);
    if (props.billingDetails.subscriptionId) {
      props.cancelSubscription(
        props.loginDetails.user,
        [props.billingDetails.subscriptionId],
        props.billingDetails.currentPass
      );
    }
  };

  // Cancel AutoRenew Subscription - Cancel Button Clicked
  const handleCancelCancelAutoRenewOnClick = () => {
    props.changeBillingValues("cancellingAutoRenewSubscription", {});
    props.changeBillingValues("showCancelAutoRenewSubscriptionPopup", false);
  };

  // Start AutoRenew Subscription - Proceed Button Clicked
  const handleProceedStartAutoRenewOnClick = () => {
    const subscriptionproduct = props.billingDetails.autoRenewSubscription;
    const subscribedProductsList = props.billingDetails.subscribedProductsList;

    const updatedProductsList = subscribedProductsList.map((product) => {
      if (product.id === subscriptionproduct.id) product.isAutoRenew = true;
      return product;
    });
    props.changeBillingValues("subscribedProductsList", updatedProductsList);
    // props.changeBillingValues("autoRenewSubscription", {});
    props.changeBillingValues("showStartAutoRenewSubscriptionPopup", false);
    handleProceedNoPaymentMethodStoredOnClick();
  };

  // Start AutoRenew Subscription - Cancel Button Clicked
  const handleCancelStartAutoRenewOnClick = () => {
    props.changeBillingValues("autoRenewSubscription", {});
    props.changeBillingValues("showStartAutoRenewSubscriptionPopup", false);
  };

  // No Payment Method Stored - Cancel Button Clicked
  const handleCancelNoPaymentMethodStoredOnClick = () => {
    props.changeBillingValues("showNoPaymentMethodStoredPopup", false);
  };

  const handleProceedNoPaymentMethodStoredOnClick = () => {
    pushEventToData("addtoCart", [
      {
        item_id: props?.billingDetails?.autoRenewSubscription?.id,
        item_name: props?.billingDetails?.autoRenewSubscription?.title
          ?.replace(/(<([^>]+)>)/gi, "")
          ?.replace(/(?:&nbsp;|amp;)/g, ""),
        currency: "SGD",
        price: props?.billingDetails?.autoRenewSubscription?.price,
        quantity: 1,
      },
    ]);
    props.changeBillingValues("showNoPaymentMethodStoredPopup", false);

    props.getMerchantAuthToken(
      props.billingDetails.autoRenewSubscription.price
    );
    props.changeProductValues("isSubscriableProduct", true);
    props.changeTransactionValues("isOpen", true);
    props.changeTransactionValues(
      "productPrice",
      props.billingDetails.autoRenewSubscription.price
    );
    props.changeProductValues(
      "selectedProduct",
      props.billingDetails.autoRenewSubscription
    );

    props.changeProductValues("isFreeEvntClicked", false);
    localStorage.setItem("pass_type", "subscription_pass");
    props.changeTransactionValues("subscribedFromMyAccountPage", true);

    props.subscribeProduct(
      props.loginDetails.user,
      props.productDetails.selectedProduct
    );
  };

  const getSubscribableProducts = async () => {
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    localStorage.setItem("pass_type", "subscription_pass");

    props.changeTransactionValues("transactionStatus", 13);
    props.changeTransactionValues("isOpen", true);
  };
  return (
    <>
      <div className="acc-details">
        <div className="head">
          <label>Manage My Pass Subscriptions</label>
        </div>
      </div>
      {props.billingDetails.paymentMethods.length === 0 && (
        <div className="payment_method_proceed">
          <Text>You do not have any active subscription.</Text>
          <div className="accept-btn" style={{ marginTop: "0px" }}>
            <Button primary onClick={getSubscribableProducts}>
              Get a Subscription
            </Button>
          </div>
        </div>
      )}
      <div className="payment-table-cover pass-subscription-table-cover">
        <table className="payment-table pass-subscription-table">
          <tr>
            <th>Pass Type</th>
            <th>Status</th>
            <th>Start Date</th>
            <th>Expiry Date</th>
            <th>Amount</th>
            <th>Auto-Renew</th>
          </tr>
          {pendingSubscription.length > 0 &&
            pendingSubscription.map((pendingsub, i) => {
              return (
                <tr className="disabled_row">
                  <td className="disabled_col">
                    {ReactHtmlParser(
                      decode(
                        pendingsub.product.title.replace(
                          /(?:\r\n|\r|\n)/g,
                          "<br />"
                        )
                      )
                    )}
                  </td>
                  <td className="active-dot-td disabled_col">
                    <span>
                      <img src={Pendingdot} />
                    </span>
                    Pending
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY">
                      {pendingsub.subscriptionresdata.firstBillingDate}
                    </Moment>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY">
                      {moment(
                        pendingsub.subscriptionresdata.firstBillingDate
                      ).add(`${pendingsub.product.validityInMonths}`, "months")}
                    </Moment>
                  </td>
                  <td className="disabled_col">S${pendingsub.product.price}</td>
                  {/* {!!pass.product.isSeasonPass &&
                    pass.product.isSeasonPass === "true" &&
                    ((pass.product.isFreeEvent &&
                      pass.product.isFreeEvent === "false") ||
                      !pass.product.isFreeEvent) && ( */}
                  <td className="switch-td disabled_col" key={i}>
                    <label class="switch">
                      <input
                        key={i}
                        id={i}
                        type="checkbox"
                        defaultChecked={false}
                      />
                      <span class="slider round"></span>
                    </label>
                  </td>
                  {/* )} */}
                </tr>
              );
            })}
          {props.transactionDetails.userPasses.length > 0 &&
            props.transactionDetails.userPasses.map((pass, k) => {
              var currentDate = moment(new Date(moment.utc().format()));
              let utcString = new Date(pass.product.expiresOn).toUTCString();
              var productExpiryDate = moment(
                new Date(moment.utc(utcString).format())
              );
              let isSubscribableProduct =
                props.productDetails.productList.filter((item) => {
                  return (
                    item.id === pass.product.id &&
                    !!item.isSubscribableProduct &&
                    item.isSubscribableProduct === true
                  );
                });

              return (
                <tr>
                  <td>
                    {ReactHtmlParser(
                      decode(
                        pass.product.title.replace(/(?:\r\n|\r|\n)/g, "<br />")
                      )
                    )}
                    <div className="d-inline active-dot-td">
                      <span>
                        <img
                          src={
                            productExpiryDate.diff(currentDate, "seconds") > 0
                              ? ActiveDot
                              : ExpiredDot
                          }
                          alt="active-status-dot"
                        />
                      </span>{" "}
                      {productExpiryDate.diff(currentDate, "seconds") > 0
                        ? "Active"
                        : "Expired"}
                    </div>
                  </td>
                  <td className="display-mobile-none active-dot-td">
                    <span>
                      <img
                        src={
                          productExpiryDate.diff(currentDate, "seconds") > 0
                            ? ActiveDot
                            : ExpiredDot
                        }
                      />
                    </span>{" "}
                    {productExpiryDate.diff(currentDate, "seconds") > 0
                      ? "Active"
                      : "Expired"}
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY">
                      {!!pass.product.isSeasonPass &&
                      pass.product.isSeasonPass === "true"
                        ? moment(pass.product.expiresOn).subtract(
                            `${pass.product.validityInMonths}`,
                            "months"
                          )
                        : pass.created_date}
                    </Moment>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY">
                      {pass.product.expiresOn}
                    </Moment>
                  </td>
                  <td>S${pass.product.price}</td>
                  {!!pass.product.price &&
                    pass.product.price > 0 &&
                    !!pass.product.isSeasonPass &&
                    pass.product.isSeasonPass === "true" &&
                    ((pass.product.isFreeEvent &&
                      pass.product.isFreeEvent === "false") ||
                      !pass.product.isFreeEvent) &&
                    productExpiryDate.diff(currentDate, "seconds") > 0 &&
                    isSubscribableProduct.length > 0 && (
                      <td className="switch-td" key={k}>
                        <label class="switch">
                          <input
                            key={k}
                            id={k}
                            type="checkbox"
                            defaultChecked={pass.product.isAutoRenew}
                            checked={pass.product.isAutoRenew}
                            onClick={(e) => {
                              handleAutoRenewToggleClick(
                                pass.product.isAutoRenew,
                                pass.subscriptionId,
                                pass.product,
                                pass
                              );
                            }}
                          />
                          <span class="slider round"></span>
                        </label>
                      </td>
                    )}
                </tr>
              );
            })}
        </table>
      </div>

      {/* Show `Cancel Auto-Renewal Subscription` */}
      {props.billingDetails?.showCancelAutoRenewSubscriptionPopup && (
        <Popup
          title="Cancel Auto-Renewal Subscription"
          body={`Cancellation will take effect at the end of your current subscription period on ${moment(
            props.billingDetails.cancellingAutoRenewSubscription.expiresOn
          ).format(
            "DD MMM YYYY"
          )} . You can restart your subscription at any time.`}
          proceedButtonText="Proceed"
          onCancelClick={handleCancelCancelAutoRenewOnClick}
          onProceedClick={handleProceedCancelAutoRenewOnClick}
        />
      )}

      {/* Show `Start Auto-Renewal Subscription` */}
      {props.billingDetails?.showStartAutoRenewSubscriptionPopup && (
        <Popup
          title="Start Auto-Renewal Subscription"
          body="Your subscription will be automatically renewed when it has reached its expiry date. Your payment method will be charged each time."
          proceedButtonText="Proceed"
          onCancelClick={handleCancelStartAutoRenewOnClick}
          onProceedClick={handleProceedStartAutoRenewOnClick}
        />
      )}

      {/* Show `No Payment Method Stored` */}
      {props.billingDetails?.showNoPaymentMethodStoredPopup && (
        <Popup
          title="No Payment Method Stored"
          body="There is no available payment method associated with this account. Please add a payment method to enable a continuous subcription."
          proceedButtonText="Proceed"
          onCancelClick={handleCancelNoPaymentMethodStoredOnClick}
          onProceedClick={handleProceedNoPaymentMethodStoredOnClick}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  billingDetails: state.Billing,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  getMerchantAuthToken: (amount) =>
    dispatch(actions.getMerchantAuthToken(amount)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  cancelSubscription: (user, subscriptionId, currentPass) =>
    dispatch(
      transActions.cancelSubscription(user, subscriptionId, currentPass)
    ),
  changeBillingValues: (f, e) =>
    dispatch(billingActions.changeBillingValues(f, e)),
  fetchProducts: (programmeId, passType, freeEventClciked) =>
    dispatch(
      prodActions.fetchProducts(programmeId, passType, freeEventClciked)
    ),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
  subscribeProduct: (user, product) =>
    dispatch(transActions.subscribeProduct(user, product)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PassSubscriptions);
