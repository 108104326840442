import { api } from "./commonapi/apiPaths";
import { headers } from "./commonapi/Headers";
import { ifTokenExpire } from "./commonapi/TokenValidation";
import { changeLoginValues } from "./Login";
import { changeTransactionValues, fetchTransaction } from "./Transaction";

export const changeWatchPartyValues = (field, value) => {
  return {
    type: "UPDATE_WATCHPARTY_VALUE",
    field,
    value,
  };
};

export const validateUserInvitation = (user) => {
  var header = headers();
  const postPayload = {};
  postPayload.client = process.env.REACT_APP_CLIENTID;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.validateInvitation, {
        headers: header,
        method: "POST",
        body: JSON.stringify(postPayload),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            if (data?.invitations?.length > 0) {
              let invitationSessionIds = data.invitations.map(
                (item) => item.session
              );
              dispatch(changeWatchPartyValues("invitations", data.invitations));
              dispatch(
                changeWatchPartyValues(
                  "invitationSessionIds",
                  invitationSessionIds
                )
              );
              dispatch(changeWatchPartyValues("isValidInvitation", data.valid));
            } else if (data.status !== "Not valid") {
              dispatch(changeTransactionValues("inviteModalStatus", 1));
              dispatch(changeTransactionValues("isInviteModalOpen", true));
              dispatch(changeWatchPartyValues("isValidInvitation", false));
              dispatch(changeWatchPartyValues("errorMsg", data.message));
            }
          }
        })
        .catch((err) => {
          var tokenExiration = ifTokenExpire(err);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(changeWatchPartyValues("errorMsg", err.message));
            dispatch(changeTransactionValues("transactionStatus", 11));
            dispatch(changeTransactionValues("isOpen", true));
          }
        });
    }
  };
};

export const checkoutAndJoinRoom = (user, invitation) => {
  var header = headers();
  const postPayload = {};
  postPayload.client = process.env.REACT_APP_CLIENTID;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  postPayload.invitation = invitation;
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.joinRoom, {
        headers: header,
        method: "POST",
        body: JSON.stringify(postPayload),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(
              changeWatchPartyValues(
                "vipSessionDetails",
                data.invitationData[0]
              )
            );
            dispatch(changeWatchPartyValues("canJoinRoom", data.valid));
            dispatch(changeTransactionValues("isOpen", false));

            dispatch(changeWatchPartyValues("loadingAccept", false));
            dispatch(fetchTransaction(user, false, "", ""));
          }
        })
        .catch((err) => {
          var tokenExiration = ifTokenExpire(err);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            // dispatch(changeTransactionValues("transactionSuccessFlag", false));
            // dispatch(changeTransactionValues("checkconsent", false));
            // dispatch(changeTransactionValues("transactionStatus", 4));
          }
        });
    }
  };
};
