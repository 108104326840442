import { combineReducers } from "redux";
import { Login } from "./Login";
import { Transaction } from "./Transaction";
import { Products } from "./Products";
import { Programmes } from "./Programmes";
import { Workshop } from "./Workshop";
import { About } from "./About";
import { VideoOnDemand } from "./VideoOnDemand";
import { Overview } from "./Overview";
import { WatchParty } from "./WatchParty";
import { UserActivities } from "./UserActivities";
import { Billing } from "./Billing";

export default combineReducers({
  Login,
  Transaction,
  Products,
  Programmes,
  Workshop,
  About,
  VideoOnDemand,
  Overview,
  WatchParty,
  UserActivities,
  Billing,
});
