const init = {
  productList: [],
  checkProductList: [],
  seasonPassList: [],
  singlePassList: [],
  bundlePassList: [],
  subscriableProductList: [],
  isSubscriableProduct: false,
  isBundleEvent: false,
  selectedProduct: {},
  productLoading: true,
  videoDetailId: "",
  isFreeEvntClicked: false,
  isValidPasscode: "",
  passcodeValidationMessage: "",
  enteredPasscode: "",
};

export const Products = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_PRODUCT_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
