import thunkMiddleware from "redux-thunk";
import createRootReducer from "../reducers";
import { createStore, applyMiddleware, compose } from "redux";

const composeEnhancers =
  (process.env.REACT_APP_NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

export const store = createStore(
  createRootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);
