import { api } from "./commonapi/apiPaths";

export const changeOverviewValues = (field, value) => {
  return {
    type: "UPDATE_OVERVIEW_VALUE",
    field,
    value,
  };
};

export const fetchOverviewDetail = () => {
  return (dispatch) => {
    return fetch(api.fetchOverview)
      .then((res) => res.json())
      .then((value) => {
        dispatch(changeOverviewValues("overviewDetail", value.data[0]));
      })
      .catch((err) => {});
  };
};
