import React from "react";
import { Route } from "react-router-dom";
import { BouncerContextHook } from "@StixNext/mojito-ui";
import { connect } from "react-redux";

function PrivateRoute({ component: Component, loginDetails, ...rest }) {
  const value = BouncerContextHook();

  const renderComponent = (props) => {
    if (loginDetails.authenticated) return <Component {...props} />;
    else {
      props.history.push("/");
      value.loginKeycloak();
    }
  };

  return <Route {...rest} render={(props) => renderComponent(props)} />;
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
});

export default connect(mapStateToProps)(PrivateRoute);
