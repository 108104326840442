import { api } from "./commonapi/apiPaths";
import { sessionSet, ifTokenExpire } from "./commonapi/TokenValidation";
import { headers } from "./commonapi/Headers";
import { changeTransactionValues } from "./Transaction";

export const changeLoginValues = (field, value) => {
  return {
    type: "UPDATE_LOGIN_VALUE",
    field,
    value,
  };
};

export const getAuthToken = () => {
  var header = headers();
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.accessToken, {
        method: "GET",
        headers: header,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(changeLoginValues("authToken", data.clientToken));
          }
        })
        .catch((err) => {});
    }
  };
};

export const getMerchantAuthToken = (amount) => {
  var header = headers();
  const payload = {};
  payload.clientid = process.env.REACT_APP_CLIENTID;
  payload.amount = amount;
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.accessToken, {
        method: "POST",
        headers: header,
        body: JSON.stringify(payload),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(
              changeTransactionValues("amountbcmetadata", {
                totalamount: data.totalamount,
                bookingcharge: data.bookingcharge,
                amount: data.amount,
              })
            );
            dispatch(changeLoginValues("authToken", data.clientToken));
          }
        })
        .catch((err) => {});
    }
  };
};
