const init = {
  videoOnDemandList: [],
  videoOnDemandDetail: [],
};

export const VideoOnDemand = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_VIDEOONDEMAND_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
