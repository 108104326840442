import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Column,
  Text,
  Button,
  BouncerContextHook,
  SisticLivePlayerAlt,
  SisticDecastPlayer,
  ChatIcon,
  FullScreenExitIcon,
  FullScreenIcon,
  EditIcon,
} from "@StixNext/mojito-ui";
import ReactHtmlParser from "react-html-parser";
import Moment from "react-moment";
import livestreamdot from "../assets/images/icons/livestream_dot.svg";
import Donation from "../assets/images/donation.png";
import Arrow from "../assets/images/leftarrow.png";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import * as progrmmeActions from "../actions/Programmes";
import LiveChat from "./LiveChat";
import LikeBox from "./LikeBox";
import { formatDateTime, userDetails, numberToString } from "../utils/utils";
import Iframe from "react-iframe";
import screenful from "screenfull";

function DryrunVideoDetail(props) {
  console.log("video detail");
  const { history, location = {} } = props;
  const loginContext = BouncerContextHook();
  const [ChatOrQA, setChatOrQA] = useState("addon");
  const [play, setPlay] = useState(false);
  const [end, setEnd] = useState(false);
  const [start, setStart] = useState(false);
  const [isEventPush, setIsEventPush] = useState(false);
  const [showInvitationBanner, setInvitationBanner] = useState(false);
  const [isVipButtonClicked, setIsVipButtonClicked] = useState(false);
  const [isEditName, setIsEditName] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [isMobile, setisMobile] = useState(window.innerWidth <= 823);
  const [theatreView, setTheatreView] = useState(false);
  const parentContainerRef = useRef(null);

  useEffect(() => {
    setIsSafari(
      window.navigator.userAgent.match(/OS X.*Safari/) &&
        !window.navigator.userAgent.match(/Chrome/)
    );
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.addEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  //to get the window screen width
  const handleWindowSizeChange = () => {
    setisMobile(window.innerWidth <= 823);
  };

  let interval = null;

  function myFunction(startDateTime, duration) {
    interval = setInterval(function () {
      var serverDate = moment(new Date(moment.utc().format()));
      var indiaDate = moment(new Date(moment.utc(startDateTime).format()));
      setStart(true);
      if (indiaDate.diff(serverDate, "seconds") < 1) {
        if (!play) {
          setPlay(true);
          setStart(false);
        }
        clearInterval(interval);
      }
    }, 1000);
  }

  let getDegitalPass = async (e, isFree) => {
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    localStorage.setItem("pass_type", isFree ? "seasonfreepass" : "seasonpass");
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        loginContext.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked"),
          "",
          ""
        );
      }
    }, 1000);
  };
  let getSingleDegitalPass = async (e) => {
    let progammeId = props.match.params.id;
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    localStorage.setItem("pass_type", "singlepass");
    props.changeProgrammesValues("selectedProgrammeId", progammeId);
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        loginContext.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked"),
          progammeId,
          ""
        );
      }
    }, 1000);
  };
  useEffect(() => {
    props.changeProductValues("isFreeEvntClicked", false);
    props.fetchChatProperties();
    props.fetchShortCodeProperties();
    let progammeId = props.match.params.id;
    if (process.env.REACT_APP_DRYRUN_PROGRAMME_IDS.includes(progammeId)) {
      props.fetchDryRunProgrammeDetail(progammeId);
    } else {
      props.fetchProgrammeDetail(progammeId);
    }
    setIsVipButtonClicked(false);
    return () => {
      clearInterval(interval);
      props.changeProgrammesValues("programme", []);
    };
  }, []);

  useEffect(() => {
    if (props.productDetails.checkProductList.length > 0) {
      props.productDetails.checkProductList.forEach((expireProd) => {
        let expireInterval = null;
        let isFixedExpiryDate = !!expireProd.isFixedExpiryDate
          ? expireProd.isFixedExpiryDate
          : false;
        let validityInMonths = !!expireProd.validityInMonths
          ? parseInt(expireProd.validityInMonths)
          : 12;
        if (!isFixedExpiryDate) {
          let expdt = new Date();
          expireProd.expiresOn = new Date(
            expdt.setMonth(expdt.getMonth() + validityInMonths)
          ).toISOString();
        }
        let utcString = new Date(expireProd.expiresOn).toUTCString();
        expireInterval = setInterval(function () {
          var serverDate = moment(new Date(moment.utc().format()));
          var indiaDate = moment(new Date(moment.utc(utcString).format()));
          if (indiaDate.diff(serverDate, "seconds") < 1) {
            props.changeProductValues(
              "checkProductList",
              props.productDetails.checkProductList.filter(
                (item) => item.id !== expireProd.id
              )
            );
            clearInterval(expireInterval);
          }
        }, 1000);
      });
    }
  }, [props.productDetails.checkProductList]);
  useEffect(() => {
    if (props.programmeDetails.programme.length > 0) {
      let progammeId = props.match.params.id;
      props.fetchCheckProducts(progammeId, "singlePass", false);
      myFunction(
        props.programmeDetails.programme[0].startTime,
        props.programmeDetails.programme[0].duration
      );
      if (
        props.programmeDetails.programme[0].ChatFlag &&
        props.programmeDetails.programme[0].QAFlag
      ) {
        setChatOrQA("chat");
      } else if (props.programmeDetails.programme[0].ChatFlag) {
        setChatOrQA("chat");
      } else if (props.programmeDetails.programme[0].QAFlag) {
        setChatOrQA("QA");
      }
    }
  }, [props.programmeDetails.programme]);

  const getSynopsis = (writeUp) => {
    let regex = /.*SYNOPSIS.*?(?=\<br>)<br>(?<synopsis>.+?(?=\<br>|&))/;
    let { groups } = writeUp.match(regex);
    return groups.synopsis;
  };

  const toggleTheatreView = () => {
    screenful.toggle(parentContainerRef.current);
    setTheatreView(!theatreView);
  };

  return (
    <>
      {props.programmeDetails.programme.length > 0 &&
        props.programmeDetails.programme.map((eachValue, key) => {
          const inPreviewMode = localStorage.getItem("inPreviewMode");
          const value =
            inPreviewMode === "true" && eachValue.preview
              ? eachValue.preview
              : eachValue;
          let progammeSessionId = props.match.params.id;
          let imageArray = value.thumbNail ? value.thumbNail.split(",") : "";
          let image =
            imageArray && imageArray.length > 1 ? imageArray[1] : imageArray[0];
          let synopsis = value.synopsis ?? getSynopsis(value.writeUp);
          return (
            <div className="row vodContainer adjustment">
              <div className="container">
                <div className="title title-nav">
                  <Link
                    to={
                      localStorage.getItem("prevPath")
                        ? localStorage.getItem("prevPath")
                        : "/"
                    }
                  >
                    <img src={Arrow} alt="icon" />
                  </Link>
                  <Text as="captionblock" className="titletext">
                    {value.liveStreamIcon && (
                      <div
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          flexFlow: "row",
                          alignItems: "center",
                          columnGap: "6px",
                        }}
                      >
                        Live stream <img src={livestreamdot} alt="icon" />
                      </div>
                    )}
                    <div dangerouslySetInnerHTML={{ __html: value.title }} />
                    <div style={{ fontSize: "13px", fontWeight: "100" }}>
                      {`${moment(new Date(value.startTime))
                        .tz("Asia/Singapore")
                        .format("DD MMM YYYY hh:mm A")}`}
                    </div>
                  </Text>
                </div>
                <div className={`wholediv ${isSafari ? "safari" : ""}`}>
                  {props.loginDetails.authenticated && (
                    <div
                      className={`videodiv disablechat${
                        !play && start ? " opac_ctrl" : ""
                      }`}
                    >
                      {value?.turnOnStaticBanner && value?.staticBanner ? (
                        <img
                          src={value?.staticBanner}
                          width="100%"
                          height={"100%"}
                        />
                      ) : (
                        <div
                          className="video-player embed-video"
                          ref={(e) => {
                            parentContainerRef.current = e;
                          }}
                          onKeyDown={(e) => e.stopPropagation()}
                        >
                          <Iframe
                            url={process.env.PUBLIC_URL + `/player.html`}
                            width="100%"
                            height="100%"
                            id="myDiv"
                            className="video-iframe"
                            allowFullScreen="true"
                            msallowfullscreen="true"
                            title="player-frame"
                            frameborder="0"
                            scrolling="no"
                            allow="autoplay"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            oallowfullscreen="true"
                          />
                          <div className="embedtabcontainer tabcontainer">
                            <div></div>
                            <div className="tabbodycontainer">
                              <div className="embedchatcontent chatcontent">
                                <div>
                                  <Iframe
                                    url={
                                      process.env.PUBLIC_URL +
                                      `/chat.html?sessionId=${
                                        value?.chatSessionId
                                      }&firstname=${
                                        props.loginDetails.user.firstname
                                      }&email=${
                                        props.loginDetails.user.email
                                      }&userid=${
                                        props.loginDetails.user.userid
                                      }&sessioncode=${
                                        value.sessionCode
                                      }&username=${btoa(
                                        props.loginDetails.user.username
                                      )}`
                                    }
                                    width="100%"
                                    height="413px"
                                    id="myId"
                                    className="pigeonhole-iframe"
                                    display="initial"
                                    position="relative"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="embedreactioncontainer">
                              <div className="embedreactioncontainerdiv">
                                <div className="in-player-likes">
                                  <LikeBox slide={value} session={props.id} />
                                </div>
                              </div>
                              <div
                                className="embedtheatrediv"
                                onClick={toggleTheatreView}
                                title="Theatre mode"
                                alt="Theatre mode"
                                role="button"
                                color={"black"}
                              >
                                {!isMobile ? (
                                  theatreView ? (
                                    <FullScreenExitIcon />
                                  ) : (
                                    <FullScreenIcon />
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {!play && start && (
                    <div
                      className={`text_div ${
                        value.ChatFlag || value.QAFlag ? "" : "disablechat"
                      }`}
                    >
                      <img src={image}></img>
                      <p>
                        Join us from
                        <br />
                        <Moment format="hh:mm a">
                          {moment(new Date(value.startTime)).tz(
                            "Asia/Singapore"
                          )}
                        </Moment>{" "}
                        SGT,{" "}
                        <Moment format="DD MMM YYYY">
                          {moment(new Date(value.startTime)).tz(
                            "Asia/Singapore"
                          )}
                        </Moment>{" "}
                      </p>
                    </div>
                  )}
                  {props.loginDetails.authenticated === null ||
                  props.loginDetails.authenticated === false ? (
                    <div className="wrap_div">
                      <img src={image} alt=""></img>
                      <div className="synopsis">
                        <strong>SYNOPSIS</strong>
                        <br />
                        <div className="synopsis_para">
                          {ReactHtmlParser(
                            synopsis
                              .split("<a")
                              .join('<a target="_blank"')
                              .replace(/(?:\r\n|\r|\n)/g, "<br />")
                          )}
                        </div>
                      </div>
                      <div className="button-group">
                        <div>
                          {props.loginDetails.authenticated === null ||
                          props.loginDetails.authenticated === false ? (
                            <div>
                              <Text as="h6" className="wrap_pass">
                                This video is only available upon login
                              </Text>
                              <Button
                                primary
                                className="video_btn"
                                onClick={(e) => {
                                  getDegitalPass(e, false);
                                }}
                              >
                                Click here to login
                              </Button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="synopiscontainer">
                <div className="synopisdiv">
                  <div className="synopissection">
                    <Text as="p">
                      {value.writeUp &&
                        ReactHtmlParser(
                          value.writeUp
                            .split("<a")
                            .join('<a target="_blank"')
                            .replace(/(?:\r\n|\r|\n)/g, "<br />")
                        )}
                    </Text>
                  </div>
                  <div className="chatsection">
                    <div className="videoDetailFeature">
                      <Text as="h4">
                        <b>VIDEO HIGHLIGHTS</b>
                      </Text>
                      <Text as="p">
                        {value.featuredPresenters &&
                          ReactHtmlParser(
                            value.featuredPresenters
                              .split("<a")
                              .join('<a target="_blank"')
                              .replace(/(?:\r\n|\r|\n)/g, "<br />")
                          )}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    programmeDetails: state.Programmes,
    productDetails: state.Products,
    watchpartyDetails: state.WatchParty,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked, sessionId, from) =>
    dispatch(
      transActions.fetchTransaction(data, buttonClicked, sessionId, from)
    ),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProgrammesValues: (f, e) =>
    dispatch(progrmmeActions.changeProgrammesValues(f, e)),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: (programmeId, passType) =>
    dispatch(prodActions.fetchProducts(programmeId, passType)),
  fetchCheckProducts: (programmeId, passType, isFreeEvent) =>
    dispatch(
      prodActions.fetchCheckProducts(programmeId, passType, isFreeEvent)
    ),
  fetchProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchProgrammeDetail(id)),
  fetchDryRunProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchDryRunProgrammeDetail(id)),
  fetchChatProperties: () => dispatch(progrmmeActions.fetchChatProperties()),
  fetchShortCodeProperties: () =>
    dispatch(progrmmeActions.fetchShortCodeProperties()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DryrunVideoDetail);
