export const transactionapi = `${process.env.REACT_APP_TRANSACTION_API_DOMAIN}${process.env.REACT_APP_TRANSACTION_API_PATH}`;
export const cms_api = `${process.env.REACT_APP_CMS_API_DOMAIN}/api/contents?tenant=${process.env.REACT_APP_CLIENTID}&collection=`;
export const product_api = `${process.env.REACT_APP_CMS_API_DOMAIN}/api/contents?tenant=${process.env.REACT_APP_CLIENTID}&collection=products`;

export const api = {
  accessToken: `${transactionapi}/payments/braintree/token`,
  checkout: `${transactionapi}/payments/braintree/checkout`,
  registerFreeEvent: `${transactionapi}/payments/braintree/registerFreeEvent`,
  fetchTnxn: `${transactionapi}/transactions/fetchPurchaseAndSubscriptionByUser`,
  products: `${transactionapi}/products/byClient`,
  passcodeValidationn: `${transactionapi}/products/discountPasscodeValidation`,
  gpurchase: `${transactionapi}/offers/redeem`,
  validateInvitation: `${transactionapi}/watchparty/validateVipInvitation`,
  joinRoom: `${transactionapi}/watchparty/joinVipRoom`,
  createUserActivity: `${transactionapi}/user_logs/createUserActivity`,
  recurring: `${transactionapi}/recurring/createSubscription`,
  cancelSubscription: `${transactionapi}/recurring/cancelSubscription`,
  fetchRecurringTnxn: `${transactionapi}/transactions/fetchPurchaseAndSubscriptionByUser`,
  createNewsletter: `${transactionapi}/newsletter/subscribenewsletter`,
  fetchNewsletterSubscription: `${transactionapi}/newsletter/fetchNewsletterSubscription`,
  fetchPlaybacks: `${cms_api}Programes&category=Playback`,
  fetchProgrammes: `${cms_api}Programes&category=Livenow`,
  fetchOverview: `${cms_api}overview`,
  fetchSubscribableProducts: `${product_api}&isSubscribableProduct=true`,
  fetchCmsProducts: `${product_api}`,
  fetchChatProperties: `${cms_api}chatProperties`,
  fetchShortCodeProperties: `${cms_api}shortCodeProperties`,
};
