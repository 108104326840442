const init = {
  hideBuyButton: false,
  showDropinComponent: false,
  transactionSuccessFlag: null,
  isOpen: false,
  transactionData: null,
  expireTransactionData: [],
  userPasses: [],
  recurringData: [],
  checkconsent: false,
  promoter: false,
  sistic: false,
  venue: false,
  transactionStatus: 0,
  inviteModalStatus: "",
  isInviteModalOpen: false,
  productPrice: 0,
  buttonClicked: false,
  loader: "none",
  paymentLoader: "block",
  amountbcmetadata: {},
  recurringDataFetch: false,
  priceList: [
    {
      id: 1,
      text: "Regular Price",
      price: "20.00",
    },
    {
      id: 2,
      text: "Student / Senior / NSF",
      price: "12.00",
    },
  ],
  singlePassIds: [],
  expireTransactionType: "",
  subscriptionTransaction: {},
  newsletterSuccessFlag: false,
  fetchtransactiondone: false,
  cancelRenewalDone: false,
  renewalDone: false,
  nonValidMessage: "",
};

export const Transaction = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_TRANSACTION_VALUE":
      return { ...state, [action.field]: action.value };
    case "REPLACE_TRANSACTION_VALUE":
      let passIndex = state.userPasses.findIndex(
        (item) => item.id === action.value.id
      );
      let user_passes = state.userPasses;
      user_passes[passIndex] = action.value;
      return { ...state, [action.field]: user_passes };
    default:
      return state;
  }
};
