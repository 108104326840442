const init = {
  paymentMethods: [],
  subscribedProductsList: [],
  removingPaymentMethod: {},
  autoRenewSubscription: {},
  cancellingAutoRenewSubscription: {},
  showRemovePaymentMethodPopup: false,
  subscriptionId: "",
  showCancelAutoRenewSubscriptionPopup: false,
  showStartAutoRenewSubscriptionPopup: false,
  showNoPaymentMethodStoredPopup: false,
  currentPass: {},
};

export const Billing = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_BILLING_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
