import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Text, Popup } from "@StixNext/mojito-ui";

import * as billingActions from "../../../actions/Billing";
import * as transActions from "../../../actions/Transaction";
import MasterCard from "../../../assets/images/master-card.png";
import VisaCard from "../../../assets/images/visa-card.png";

function PaymentMethod(props) {
  useEffect(() => {
    if (props.transactionDetails.recurringData.length > 0) {
      let activePaymentMethods = props.transactionDetails.recurringData.filter(
        (pass) => {
          return (
            pass?.subscriptionresdata?.status === "Pending" ||
            pass?.subscriptionresdata?.status === "Active"
          );
        }
      );

      activePaymentMethods = activePaymentMethods.map((pm) => {
        try {
          pm.reqdata.details = JSON.parse(pm.reqdata.details);
        } catch (error) {
          console.log(error);
          return pm;
        }
        return pm;
      });

      // removing duplicate payment methods based on (cardType, bin and lastFour)
      activePaymentMethods = activePaymentMethods.filter(
        (pm, index, array) =>
          array.findIndex(
            (p) =>
              p.reqdata.details.cardType === pm.reqdata.details.cardType &&
              p.reqdata.details.bin === pm.reqdata.details.bin &&
              p.reqdata.details.lastFour === pm.reqdata.details.lastFour
          ) === index
      );

      props.changeBillingValues("paymentMethods", activePaymentMethods);
    }
  }, [props.transactionDetails.recurringData]);

  const handleRemovePaymentMethod = (paymentMethod) => {
    props.changeBillingValues("showRemovePaymentMethodPopup", true);
    props.changeBillingValues("removingPaymentMethod", paymentMethod);
  };

  const handleOnProceedClick = () => {
    const removingPaymentMethod = props.billingDetails.removingPaymentMethod;
    const paymentMethodsList = props.billingDetails.paymentMethods;
    const subscribedProductsList = props.billingDetails.subscribedProductsList;

    const updatedPaymentMethodsList = paymentMethodsList.filter(
      (pm) => pm.id !== removingPaymentMethod.id
    );
    props.changeBillingValues("paymentMethods", updatedPaymentMethodsList);
    props.changeBillingValues("showRemovePaymentMethodPopup", false);

    const updatedSubscribedProductsList = subscribedProductsList.map(
      (product) => {
        product.isAutoRenew = false;
        return product;
      }
    );
    props.changeBillingValues(
      "subscribedProductsList",
      updatedSubscribedProductsList
    );
    let subscriptionIds = props.transactionDetails.recurringData
      .filter((item) => {
        return (
          item?.subscriptionresdata?.status === "Pending" ||
          item?.subscriptionresdata?.status === "Active"
        );
      })
      .map((pass) => pass.subscriptionresdata.id);
    props.cancelSubscription(props.loginDetails.user, subscriptionIds, "");
    props.changeBillingValues("removingPaymentMethod", {});
  };

  const handleOnCancelClick = () => {
    props.changeBillingValues("removingPaymentMethod", {});
    props.changeBillingValues("showRemovePaymentMethodPopup", false);
  };

  const getSubscribableProducts = async () => {
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    localStorage.setItem("pass_type", "subscription_pass");

    props.changeTransactionValues("transactionStatus", 13);
    props.changeTransactionValues("isOpen", true);
  };

  return (
    <>
      <div className="acc-details">
        <div className="head">
          <label>Manage Payment Method</label>
        </div>
      </div>
      {props.billingDetails?.paymentMethods.length > 0 ? (
        <div className="payment-table-cover">
          <table className="payment-table payment-methods-table">
            <tr>
              <th>Card Type</th>
              <th>Ending In</th>
              <th>Expires</th>
              <th>Action</th>
            </tr>
            {props.billingDetails?.paymentMethods.map((paymentMethod) => {
              let cardDetails = paymentMethod.reqdata.details;
              return (
                <tr key={paymentMethod.id}>
                  <td className="master-card-td">
                    <span>
                      {cardDetails.cardType === "Visa" && (
                        <img src={VisaCard} alt="card-type" />
                      )}
                      {cardDetails.cardType === "MasterCard" && (
                        <img src={MasterCard} alt="card-type" />
                      )}
                    </span>{" "}
                    {cardDetails.cardType}
                  </td>
                  <td>**** **** **** {cardDetails.lastFour}</td>
                  <td>{`${cardDetails.expirationMonth}/${cardDetails.expirationYear}`}</td>
                  <td
                    className="payment-remove"
                    onClick={() => handleRemovePaymentMethod(paymentMethod)}
                  >
                    Remove
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      ) : (
        <div className="payment_method_proceed">
          <Text>
            You have no auto-renewal subscriptions to store your payment method.
          </Text>
          <div className="accept-btn" style={{ marginTop: "0px" }}>
            <Button primary onClick={getSubscribableProducts}>
              Get a Subscription
            </Button>
          </div>
        </div>
      )}

      {/* Show `Remove payment method popup` */}
      {props.billingDetails?.showRemovePaymentMethodPopup && (
        <Popup
          title="Removing Payment Method"
          body="There is an active subscription on this account that requires a payment method stored. If you wish to proceed, all auto-renewal subcriptions will be switched off."
          proceedButtonText="Proceed"
          onCancelClick={handleOnCancelClick}
          onProceedClick={handleOnProceedClick}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  billingDetails: state.Billing,
  transactionDetails: state.Transaction,
});

const mapDispatchToProps = (dispatch) => ({
  changeBillingValues: (f, e) =>
    dispatch(billingActions.changeBillingValues(f, e)),

  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  cancelSubscription: (user, subscriptionId, currentPass) =>
    dispatch(
      transActions.cancelSubscription(user, subscriptionId, currentPass)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
