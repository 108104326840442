const init = {
  invitations: [],
  isValidInvitation: false,
  canJoinRoom: false,
  vipSessionDetails: {},
  loadingAccept: false,
  earlierProgramme: {},
  invitationSessionIds: [],
  errorMsg: "",
};

export const WatchParty = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_WATCHPARTY_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
