import { theme } from "@StixNext/mojito-ui";

theme.mojito.colors.primaryBlue = "#fe0300";
theme.mojito.colors.primaryHover = "#fe0300";
theme.mojito.colors.primaryActive = "#fe0300";
theme.mojito.colors.primaryDisabled = "#fe0300";
theme.mojito.colors.checkBoxBorderColor = "#fe0300;";
theme.mojito.colors.checkBoxBackgroundColor = "#fe0300";
theme.mojito.colors.disabledText = "#FFFFFF";
theme.mojito.colors.primaryMenuHover = "#ff0200";
theme.mojito.buttonBorder.buttonBorderRadius = "0px";
theme.mojito.opacities.checkboxOpacity = "0";
theme.mojito.carouselThemeColors.ActiveIndices = "#ff020c";
theme.mojito.colors.primaryModalBackground = "rgba(0, 0, 0, 0.81)";
theme.mojito.colors.primarySalmon = "#ffffff";
export { theme };
