import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Shell,
  Menu,
  BouncerContextHook,
  BouncerContext,
  CloseWhiteIcon,
  CloseIcon,
  B2CHeader,
  Grid,
  Column,
  Row,
  Modal,
  Button,
  Text,
} from "@StixNext/mojito-ui";
import overviewred from "../../assets/images/icons/overview_red.svg";
import livered from "../../assets/images/icons/live_red.svg";
import upcomingred from "../../assets/images/icons/upcoming_red.svg";
import vodred from "../../assets/images/icons/vod_red.svg";
import watchred from "../../assets/images/icons/watch_red.svg";
import logo from "../../assets/images/sso-icon-logo.svg";
import head from "../../assets/images/sso-text-logo.svg";
import logomob from "../../assets/images/SSO-logo.jpg";
// import Keycloak from "keycloak-js";
import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import * as prodActions from "../../actions/Products";
import * as watchpartyActions from "../../actions/WatchParty";
import * as programmeActions from "../../actions/Programmes";
import * as userActions from "../../actions/UserActivities";
import * as overviewActions from "../../actions/Overview";
import queryString from "query-string";
import { TransactionStatus } from "../BuyProductAndTransaction";
import _ from "lodash";
import TagManager from "react-gtm-module";
import Moment from "react-moment";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import decode from "decode-html";
import { userDetails } from "../../utils/utils";
import InviteModal from "./InviteModal";
import DiscountModal from "./DiscountModal";

function Header(props) {
  const { history, location = {} } = props;
  const { pathname = "/" } = location;
  const [keycloak, setKeycloak] = useState({});
  const [closeModal, setCloseModal] = useState(false);
  const [isDiscountOpen, setIsDiscountOpen] = useState(false);

  const value = BouncerContextHook();
  let onClose = () => {
    // props.changeTransactionValues("productPrice", 0);
    props.changeTransactionValues("showDropinComponent", false);
    props.changeTransactionValues("transactionStatus", 0);
    props.changeTransactionValues("checkconsent", false);
    props.changeTransactionValues("isOpen", false);
    // props.changeTransactionValues("hideBuyButton", false);
    props.changeTransactionValues("productPrice", 0);
    localStorage.setItem("buttonClicked", false);
    localStorage.setItem("subscribeButtonClicked", false);
    localStorage.removeItem("productId");
    props.changeProductValues("selectedProduct", {});
    props.changeProductValues("isFreeEvntClicked", false);
    props.changeProductValues("isBundleEvent", false);
    props.changeProductValues("enteredPasscode", "");
    props.changeProductValues("isValidPasscode", "");
    props.changeProductValues("passcodeValidationMessage", "");

    if (localStorage.getItem("grouppasslogin") == "true") {
      localStorage.setItem("grouppasslogin", false);
      localStorage.setItem("grouppass", false);
      localStorage.removeItem("grouppassid");
    }
    if (localStorage.getItem("discountPassRedemption") == "true") {
      localStorage.setItem("discountPassRedemption", false);
      localStorage.removeItem("discountCode");
      localStorage.removeItem("discountProductId");
    }
    if (localStorage.getItem("grouppasscompleted") == "false") {
      props.changeTransactionValues("showDropinComponent", false);
      props.changeTransactionValues("checkconsent", false);
    }
  };
  const onInviteModalClose = () => {
    props.changeTransactionValues("inviteModalStatus", 0);
    props.changeTransactionValues("isInviteModalOpen", false);
  };
  if (
    Object.keys(value.keycloak).length > 0 &&
    Object.keys(keycloak).length < 1
  ) {
    setKeycloak(value.keycloak);
  } else if (
    Object.keys(value.keycloak).length > 0 &&
    Object.keys(keycloak).length > 0 &&
    !_.isEqual(keycloak, value.keycloak)
  ) {
    setKeycloak(value.keycloak);
  }

  useEffect(() => {
    if (keycloak.authenticated) {
      props.changeLoginValues(
        "authenticateduser",
        keycloak.tokenParsed.preferred_username
      );
      props.changeLoginValues("user", {
        ...props.loginDetails.user,
        keycloakAccessToken: keycloak.accessToken,
        firstname: keycloak.tokenParsed.given_name,
        lastname: keycloak.tokenParsed.family_name,
        username: keycloak.tokenParsed.preferred_username,
        email:
          keycloak.tokenParsed.email || keycloak.tokenParsed.preferred_username,
        userid: keycloak.tokenParsed.sub,
      });
      props.changeLoginValues("accessToken", keycloak.token);
      localStorage.setItem("dch_access_token", keycloak.token);
      props.changeLoginValues("keycloak", keycloak);
      props.changeLoginValues("authenticated", keycloak.authenticated);
      localStorage.setItem("sso_authenticated", keycloak.authenticated);
      TagManager.dataLayer({
        dataLayer: {
          event: "Login",
          userId: keycloak.tokenParsed.sub,
          firstname: keycloak.tokenParsed.given_name,
          lastname: keycloak.tokenParsed.family_name,
          email: keycloak.tokenParsed.email,
        },
      });
    }
  }, [keycloak]);

  useEffect(() => {
    if (
      props.programmeDetails.programmesList.length > 0 &&
      props.watchpartyDetails.invitations.length > 0
    ) {
      let invitationSessionIds = props.watchpartyDetails.invitations.map(
        (item) => item.session
      );
      let filteredProgrammeList = props.programmeDetails.programmesList
        .filter((item) => {
          var todayDate = moment(new Date(moment.utc().format()));
          var startSessionDate = moment(
            new Date(
              moment.utc(item.startTime).add(item.duration, "minutes").format()
            )
          );
          return (
            invitationSessionIds.includes(item.id) &&
            todayDate.diff(startSessionDate, "seconds") < 1
          );
        })
        .sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
      if (filteredProgrammeList.length > 0) {
        props.changeTransactionValues("inviteModalStatus", 1);
        props.changeTransactionValues("isInviteModalOpen", true);
      }
    }
  }, [
    props.programmeDetails.programmesList,
    props.watchpartyDetails.invitations,
  ]);

  const registerUserLogs = async (eventType, otherEventInfo) => {
    if (eventType === "Logout") {
      localStorage.setItem("sso_authenticated", false);
    }
    let deviceDetail = await userDetails().then((data) => {
      return data;
    });
    props.createUserLogs(
      {
        ...props.loginDetails.user,
        sessionId: props.loginDetails.keycloak.sessionId,
      },
      deviceDetail,
      {},
      eventType
    );

    if (pathname === "/myaccount") history.push("/");
  };
  let getDegitalPass = async (e) => {
    setCloseModal(false);
    props.changeProductValues("isFreeEvntClicked", false);
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    localStorage.setItem("pass_type", "seasonpass");
    setTimeout(() => {
      setCloseModal(false);
      if (!props.loginDetails.authenticated) {
        value.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked"),
          "",
          ""
        );
      }
    }, 500);
  };

  useEffect(() => {
    // let progammeId = props.match.params.id;
    if (
      props.loginDetails.authenticated &&
      (props.transactionDetails.hideBuyButton ||
        props.transactionDetails.expireTransactionData.length > 0)
    ) {
      if (props.transactionDetails.expireTransactionData.length > 0) {
        props.transactionDetails.expireTransactionData.forEach((expireTran) => {
          let expireInterval = null;
          let utcString = new Date(expireTran.product.expiresOn).toUTCString();
          expireInterval = setInterval(function () {
            var serverDate = moment(new Date(moment.utc().format()));
            var indiaDate = moment(new Date(moment.utc(utcString).format()));
            if (indiaDate.diff(serverDate, "seconds") < 1) {
              if (
                expireTran.product.isSeasonPass === "true" &&
                ((expireTran.product.isFreeEvent &&
                  expireTran.product.isFreeEvent === "false") ||
                  !expireTran.product.isFreeEvent)
              ) {
                props.changeTransactionValues("hideBuyButton", false);
                props.changeTransactionValues("transactionSuccessFlag", false);
              }
              props.changeTransactionValues(
                "expireTransactionData",
                props.transactionDetails.expireTransactionData.filter(
                  (item) => item.id !== expireTran.id
                )
              );
              clearInterval(expireInterval);
            }
          }, 1000);
        });
      }
    }
  }, [
    props.loginDetails.authenticated,
    props.transactionDetails.hideBuyButton,
    props.transactionDetails.expireTransactionData,
  ]);

  let logoutKeycloack = (e) => {
    e.preventDefault();
    props.loginDetails.keycloak.logout();
  };

  useEffect(() => {
    if (props.loginDetails.authenticated) {
      // props.getAuthToken();
      if (props.loginDetails.user.userid) {
        let isReload = localStorage.getItem("isPageReload");
        if (isReload && isReload === "true") {
          registerUserLogs("Reload/Refresh", {});
          localStorage.setItem("isPageReload", false);
        }
        registerUserLogs("Login", {});
        if (localStorage.getItem("grouppasslogin") == "true") {
          props.grouppassTransaction(
            props.loginDetails.user,
            localStorage.getItem("grouppassid")
          );
        }
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked"),
          "",
          ""
        );
        props.fetchProgrammes();
        props.validateUserInvitation(props.loginDetails.user);
        props.fetchNewsletter(props.loginDetails.user);
      }
    } else {
      props.changeTransactionValues("hideBuyButton", false);
    }
  }, [props.loginDetails.authenticated, props.loginDetails.user.userid]);

  useEffect(() => {
    if (
      props.loginDetails.authenticated &&
      props.loginDetails.user.userid &&
      pathname.includes("discount")
    ) {
      if (
        localStorage.getItem("discountPassRedemption") == "true" &&
        props?.productDetails?.productList?.length > 0 &&
        props.transactionDetails.fetchtransactiondone
      ) {
        let discountProductId = localStorage.getItem("discountProductId");
        let selectedProduct = props?.productDetails?.productList.filter(
          (item) => item.id === discountProductId
        );

        let isDedicatedLinkAllowed = false;
        if (
          props?.transactionDetails?.expireTransactionData?.some((data) => {
            return (
              data?.product?.isSeasonPass &&
              data?.product?.isSeasonPass === "true" &&
              ((data?.product?.isFreeEvent &&
                data?.product?.isFreeEvent === "false") ||
                !data?.product?.isFreeEvent)
            );
          })
        ) {
          isDedicatedLinkAllowed = true;
        } else {
          let length = 0;
          let sessionsMatch = selectedProduct[0]?.sessionIds
            ?.split(",")
            .map((item) => {
              return props?.transactionDetails?.expireTransactionData?.map(
                (data) => {
                  return (
                    data?.product?.sessionIds?.split(",")?.length > 0 &&
                    data?.product?.sessionIds?.split(",")?.some((session) => {
                      if (item === session) {
                        length += 1;
                      }
                      return item === session;
                    })
                  );
                }
              );
            });
          if (length === selectedProduct[0]?.sessionIds?.split(",")?.length) {
            isDedicatedLinkAllowed = true;
          }
        }
        if (isDedicatedLinkAllowed) {
          localStorage.setItem("discountPassRedemption", false);
          localStorage.removeItem("discountCode");
          localStorage.removeItem("discountProductId");
        } else {
          let discountPasscode = localStorage.getItem("discountCode");
          props.changeTransactionValues(
            "productPrice",
            selectedProduct[0]?.price
          );
          props.changeProductValues("selectedProduct", selectedProduct[0]);
          props.changeProductValues("enteredPasscode", discountPasscode);
          props.getMerchantAuthToken(selectedProduct[0]?.price);
          props.discountPasscodeValidation(discountProductId, discountPasscode);
        }
      }
    } else if (
      (!localStorage.getItem("sso_authenticated") ||
        localStorage.getItem("sso_authenticated") === "false") &&
      pathname.includes("discount")
    ) {
      value.loginKeycloak();
    }
  }, [
    props.loginDetails.authenticated,
    props.loginDetails.user.userid,
    props.productDetails.productList,
    props.transactionDetails.expireTransactionData,
    props.transactionDetails.fetchtransactiondone,
    pathname,
  ]);

  useEffect(() => {
    value.transactionSuccessFlag =
      props.transactionDetails.transactionSuccessFlag;
  }, [props.transactionDetails.transactionSuccessFlag]);

  useEffect(() => {
    if (props.loginDetails.tokenExpired == "Token Expired") {
      value.loginKeycloak();
      props.changeLoginValues("tokenExpired", "");
      props.changeLoginValues("logout", true);
    }
    // if (props.loginDetails.logout) {
    //   value.loginKeycloak();
    // }
  }, [props.loginDetails.tokenExpired, props.loginDetails.logout]);

  const handleNavigation = (path) => (e) => {
    e.preventDefault();
    history.push(path);
  };

  useEffect(() => {
    localStorage.setItem("sso_authenticated", false);
    props.fetchOverview();
    props.fetchProducts({}, "", false);
  }, []);

  useEffect(() => {
    var todayDate = moment(new Date(moment.utc().format()));
    var startSessionDate = moment(
      new Date(moment.utc(props?.overviewDetail?.startDiscountSale).format())
    );
    var endSessionDate = moment(
      new Date(moment.utc(props?.overviewDetail?.endDiscountSale).format())
    );
    if (
      props?.overviewDetail?.enable1010sale &&
      todayDate.diff(endSessionDate, "seconds") < 0
    ) {
      setIsDiscountOpen(true);
    }
  }, [props.overviewDetail]);

  return (
    <>
      <B2CHeader>
        <B2CHeader.Banner className="SSO_header">
          <div className="header-box">
            <a
              target="_blank"
              rel="noreferrer"
              href={"https://www.sso.org.sg/"}
            >
              <img
                // onClick={(e) => {
                //   e.preventDefault();
                //   history.push("/");
                // }}
                className="header-img"
                src={head}
              ></img>
            </a>
            {/* <Link to="/" className="main-logo">
              Back to mainsite
            </Link> */}
            {/* <img
              className="main-logo"
              onClick={(e) => {
                e.preventDefault();
                history.push("/");
              }}
              src={logo}
              alt="image"
            /> */}
          </div>
        </B2CHeader.Banner>
        <B2CHeader.PoweredByBanner className="poweredby_tab">
          <B2CHeader.UserBuyPass
            closeModal={closeModal}
            updateCloseModal={setCloseModal}
            className="user_buypass"
            samePopupForMob={true}
          >
            <B2CHeader.PopOver logoutActivity={registerUserLogs}>
              <B2CHeader.PopOverItem>
                {!value.sessionRestrictor &&
                props.loginDetails.authenticated &&
                props.transactionDetails.expireTransactionData.length > 0 ? (
                  props.transactionDetails.expireTransactionData.map(
                    (pass, k) => {
                      return (
                        <>
                          <div
                            className={`popover_success_passcard ${
                              props.transactionDetails.expireTransactionData
                                .length > 1 &&
                              k !==
                                props.transactionDetails.expireTransactionData
                                  .length -
                                  1 &&
                              "space_card"
                            }`}
                            key={k}
                          >
                            <div className="pass_container">
                              {pass.product.isSeasonPass !== "true" && (
                                <span className="passtypespan">
                                  {pass.product.sessionIds.split(",").length ===
                                  1
                                    ? pass.product.isFreeEvent === "true"
                                      ? "Free Single Access Pass"
                                      : "Single Access Pass"
                                    : ""}
                                </span>
                              )}
                              {pass.product.isSeasonPass === "true" && (
                                <span className="passtypespan">
                                  12M{" "}
                                  {pass.product.isFreeEvent === "true"
                                    ? "BASIC ACCESS PASS HOLDER "
                                    : "PREMIUM ALL-ACCESS PASS HOLDER"}{" "}
                                </span>
                              )}
                              {pass.product.isSeasonPass !== "true" &&
                                pass.product.title && (
                                  <span className="passtitleheader">
                                    {ReactHtmlParser(
                                      decode(
                                        pass.product.title.replace(
                                          /(?:\r\n|\r|\n)/g,
                                          "<br />"
                                        )
                                      )
                                    )}
                                  </span>
                                )}
                            </div>
                            <span className="span_mail">
                              Expiry Date{" "}
                              <Moment format="DD MMM YYYY">
                                {new Date(
                                  pass.product.expiresOn
                                ).toLocaleString("en-SG", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })}
                                {/* {pass.product.expiresOn} */}
                              </Moment>
                            </span>
                            {/* <span
                            className="span_mail"
                            style={{ fontSize: "12px", paddingTop: "15px" }}
                          >
                            {props.loginDetails.keycloak.tokenParsed.email}
                          </span> */}
                          </div>
                        </>
                      );
                    }
                  )
                ) : (
                  <div className="popover_passcard">
                    <span className="dont_access_mail">
                      You don't have a 12M Premium All-Access Pass yet
                    </span>
                    <a
                      href="#"
                      onClick={getDegitalPass}
                      style={{
                        cursor: "pointer",
                        color: "#fe0300",
                        textDecoration: "none",
                      }}
                    >
                      Click here to buy now
                    </a>
                  </div>
                )}
                <hr />
              </B2CHeader.PopOverItem>
              <B2CHeader.PopOverItem>
                <a
                  style={{
                    cursor: "pointer",
                    color: "black",
                    textDecoration: "none",
                  }}
                  onClick={() => history.push("/myaccount")}
                >
                  My Account
                </a>
              </B2CHeader.PopOverItem>
            </B2CHeader.PopOver>
          </B2CHeader.UserBuyPass>
        </B2CHeader.PoweredByBanner>
        <B2CHeader.Navigation className="nav-header">
          {(/^\/watch/i.test(pathname) &&
            /\watch\/[a-zA-Z]|[0-9]|[&._-]/.test(pathname)) ||
          (/^\/playback/i.test(pathname) &&
            /\playback\/[a-zA-Z]|[0-9]|[&._-]/.test(pathname)) ||
          (/^\/upcoming/i.test(pathname) &&
            /\upcoming\/[a-zA-Z]|[0-9]|[&._-]/.test(pathname)) ||
          pathname === "/myaccount" ||
          pathname.includes("/autorenew") ? (
            <div></div>
          ) : (
            <Menu
              className="SSO_Menu"
              items={[
                {
                  menuItem: "Overview",
                  navigation: "/overview",
                  onClick: handleNavigation("/overview"),
                  active: pathname === "/overview" || pathname === "/",
                },
                // {
                //   menuItem: "Schedule",
                //   navigation: "/schedule",
                //   onClick: handleNavigation("/schedule"),
                //   active: pathname === "/schedule",
                // },
                {
                  menuItem: "Watch Now",
                  navigation: "/watch",
                  onClick: handleNavigation("/watch"),
                  active: pathname === "/watch",
                },
                {
                  menuItem: "Upcoming",
                  navigation: "/upcoming",
                  onClick: handleNavigation("/upcoming"),
                  active: pathname === "/upcoming",
                },
                {
                  menuItem: "Playback",
                  navigation: "/playback",
                  onClick: handleNavigation("/playback"),
                  active: pathname === "/playback",
                },
                {
                  menuItem: "About",
                  navigation: "/about",
                  onClick: handleNavigation("/about"),
                  active: pathname === "/about",
                },
              ]}
            ></Menu>
          )}
        </B2CHeader.Navigation>
      </B2CHeader>
      <>
        <TransactionStatus
          isOpen={props.transactionDetails.isOpen}
          onClose={onClose}
          transactionSuccessFlag={
            props.transactionDetails.transactionSuccessFlag
          }
          transactionData={props.transactionDetails.transactionData}
          username={props.loginDetails.keycloak}
          {...props}
        />
        <InviteModal
          isOpen={props.transactionDetails.isInviteModalOpen}
          onClose={onInviteModalClose}
          {...props}
        />
        <DiscountModal
          isDiscountOpen={isDiscountOpen}
          setIsDiscountOpen={setIsDiscountOpen}
          overviewDetail={props?.overviewDetail}
        />
      </>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    productDetails: state.Products,
    userActivityDetails: state.UserActivities,
    overviewDetail: state.Overview,
    watchpartyDetails: state.WatchParty,
    programmeDetails: state.Programmes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked, sessionId, from) =>
    dispatch(
      transActions.fetchTransaction(data, buttonClicked, sessionId, from)
    ),
  grouppassTransaction: (user, passid) =>
    dispatch(transActions.grouppassTransaction(user, passid)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  fetchProducts: (programmeId, passType, freeEventClciked) =>
    dispatch(
      prodActions.fetchProducts(programmeId, passType, freeEventClciked)
    ),
  validateUserInvitation: (user) =>
    dispatch(watchpartyActions.validateUserInvitation(user)),
  fetchProgrammes: () => dispatch(programmeActions.fetchProgrammes()),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
  changeUserActivityValues: (f, e) =>
    dispatch(userActions.changeUserActivityValues(f, e)),
  createUserLogs: (user, device, session, eventType) =>
    dispatch(userActions.createUserLogs(user, device, session, eventType)),
  fetchOverview: () => dispatch(overviewActions.fetchOverviewDetail()),
  fetchNewsletter: (user) => dispatch(transActions.fetchNewsletter(user)),
  discountPasscodeValidation: (productid, passcode) =>
    dispatch(prodActions.discountPasscodeValidation(productid, passcode)),
  getMerchantAuthToken: (amount) =>
    dispatch(actions.getMerchantAuthToken(amount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
