import { getVideoOnDemand } from "./commonapi/mockData";
import { api } from "./commonapi/apiPaths";
import { changeProductValues } from "./Products";

export const changeVideoOnDemandValues = (field, value) => {
  return {
    type: "UPDATE_VIDEOONDEMAND_VALUE",
    field,
    value,
  };
};

export const fetchVideoOnDemandDetail = (progammeId) => {
  const inPreviewMode = localStorage.getItem("inPreviewMode");

  return (dispatch) => {
    return fetch(
      api.fetchPlaybacks + (inPreviewMode === "true" ? "&previewMode=true" : "")
    )
      .then((res) => res.json())
      .then((value) => {
        let programmeDetail = value.data.filter(function (item) {
          return item.id === progammeId;
        });
        dispatch(changeProductValues("videoDetailId", progammeId));
        dispatch(
          changeVideoOnDemandValues("videoOnDemandDetail", programmeDetail)
        );
      })
      .catch((err) => {});
  };
};

export const fetchPlayback = () => {
  return (dispatch) => {
    return fetch(api.fetchPlaybacks)
      .then((res) => res.json())
      .then((value) => {
        dispatch(
          changeVideoOnDemandValues(
            "videoOnDemandList",
            Array.isArray(value.data) ? value.data : []
          )
        );
      })
      .catch((err) => err);
  };
};

export const fetchDryRunPlaybackDetail = (progammeId) => {
  return (dispatch) => {
    getVideoOnDemand()
      .then((data) => {
        let programmeDetail = data.filter(function (item) {
          return item.id === progammeId;
        });
        dispatch(
          changeVideoOnDemandValues("videoOnDemandDetail", programmeDetail)
        );
      })
      .catch((err) => {});
  };
};
