import React from "react";
import { connect } from "react-redux";
import { Text, Button, BouncerContextHook } from "@StixNext/mojito-ui";

import main from "../../assets/images/main.jpg";

import { theme } from "./../../Theme";
import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import * as prodActions from "../../actions/Products";

function GroupPassEntry(props) {
  const value = BouncerContextHook();
  return (
    <>
      <div
        style={{
          padding: "10px 0px",
        }}
      >
        <Grid>
          <Row>
            <Column xl={5} xs={12} className={"payment-section-text"}>
              <img className="main_image" src={main} alt="icon" />
            </Column>
            <Column
              xl={7}
              xs={12}
              className={"payment-section-text"}
              style={{
                flexFlow: "column",
              }}
            >
              <Row>
                <Text as="h4" className="group_pass_head">
                  Thank you for purchasing the Singapore Writers Festival 2020
                  Digital Festival Pass.
                </Text>
              </Row>
              <Row>
                <Text as="h6" className="group_pass_login_section">
                  Click on the link below to login with your SISTIC Account and
                  start enjoying all the great content here.
                </Text>
              </Row>
              <Row className="button_row">
                <Button
                  primary
                  onClick={(e) => {
                    localStorage.setItem("logout", false);
                    props.changeTransactionValues("isOpen", false);
                    localStorage.setItem("grouppasslogin", true);
                    value.loginKeycloak();
                  }}
                  className="login_btn"
                >
                  Login Now
                </Button>
              </Row>
            </Column>
          </Row>
        </Grid>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, clicked) =>
    dispatch(transActions.fetchTransaction(data, clicked)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupPassEntry);
