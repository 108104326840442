import React from "react";
import { Shell, Card, Text, Button } from "@StixNext/mojito-ui";
import AboutSsoImg from "../assets/images/about-sso.jpg";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
import * as actions from "../actions/About";
import { connect } from "react-redux";
import { useEffect } from "react";

const AboutSso = (props) => {
  useEffect(() => {
    props.fetchVenue();
  }, []);

  return (
    <Shell.Body className="css-watch-free adjustment">
      <Grid>
        <Row>
          <img src={AboutSsoImg} />
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Column xs={12} className="about_sso_div">
            <h3 className="about-heading">
              ABOUT THE SINGAPORE SYMPHONY ORCHESTRA
            </h3>
          </Column>
          <Column className="about-content">
            Founded in 1979, the Singapore Symphony Orchestra (SSO) is
            Singapore’s flagship orchestra, touching lives through classical
            music and providing the heartbeat of the cultural scene. In addition
            to its subscription series concerts, the SSO is also known for its
            outdoor and community appearances. The orchestra also presents
            intimate chamber performances in the VCHpresents series, the annual
            Singapore International Piano Festival (SIPF) and the biennial
            National Piano and Violin Competition (NPVC) at the Victoria Concert
            Hall, Home of the SSO. The SSO is part of the Singapore Symphony
            Group, which also manages the Singapore Symphony Choruses, and the
            Singapore National Youth Orchestra. The mission of the Group is to
            create memorable shared experiences with music. Through the SSO and
            its affiliated performing groups, we spread the love for music,
            nurture talent and enrich our diverse communities.
            <br />
            <br />
            The Singapore Symphony Orchestra was nominated for and clinched
            third place in the prestigious Gramophone Orchestra of the Year
            Award 2021.
            <br />
            <br />
            Visit Singapore Symphony Orchestra’s website and find out about the
            in-person concerts{" "}
            <a href="https://www.sso.org.sg/" target={"_blank"}>
              HERE
            </a>
            .
          </Column>
          <Column xs={12} className="about_sso_div">
            <h3 className="about-heading">
              ABOUT THE SINGAPORE SYMPHONY CHORUSES
            </h3>
          </Column>
          <Column className="about-content">
            The Singapore Symphony Choruses is a premier choral body comprising
            the Singapore Symphony Chorus, Singapore Symphony Youth Choir and
            Singapore Symphony Children’s Choir.
            <br />
            <br />
            Since 1980, the Choruses have seen membership from diverse ages,
            backgrounds and nationalities. Regularly sharing the stage with the
            SSO, the Choruses record and tour with the orchestra, as well as
            give their own performances in Singapore and abroad. Through
            holistic training that nurtures artistic growth and personal
            development, the Choruses aim to articulate an ecosystem of choral
            excellence. Repertoire for the respective choruses straddle musical
            worlds and styles, ranging from orchestral masterpieces to a
            cappella favourites, and spanning the Renaissance to contemporary
            21-st century music. Throughout the pandemic, the Choruses
            challenged the boundaries of remote collaboration in music making,
            releasing several productions including a music video "We Will Get
            There", as well as a one-of-its-kind joint choral performance
            "Longing", entirely enabled by technology.
            <br />
            <br />
            Find out more about the Singapore Symphony Choruses{" "}
            <a
              href="https://www.sso.org.sg/about/singapore-symphony-choruses"
              target={"_blank"}
            >
              HERE
            </a>
            .
            <br />
            <br />
          </Column>
        </Row>
      </Grid>
    </Shell.Body>
  );
};

const mapStateToProps = (state) => {
  return {
    aboutSso: state.About.AboutSso,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchVenue: (f, e) => dispatch(actions.fetchVenue(f, e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutSso);
