import React from "react";
import { Shell, Card, Text, Button } from "@StixNext/mojito-ui";
import * as Images from "./common/watch-free";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
export const watchfree = (props) => {
  let watchList = [
    {
      image: Images.Image_1001,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1002,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1003,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1004,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1005,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1006,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1007,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1008,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1009,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1010,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1011,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1012,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1001,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1002,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1003,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1004,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1005,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1006,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1007,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1008,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1009,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1010,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1011,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
    {
      image: Images.Image_1012,
      title: "TITLE OF THE SHOW",
      duration: "Duration: 60mins ",
      uploaded: "Uploaded on: 14 Dec 2020",
    },
  ];
  const handleClick = (eventId = "FP2") => {
    props.history.push(`/playback/${eventId}`);
  };
  return (
    <Shell.Body className="css-watch-free">
      <Grid>
        <Row>
          {/* <Column xl={4} xs={12}> */}
          {watchList.map((slide, index) => {
            return (
              <div>
                <Card
                  key={index}
                  className={"video-slides"}
                  onClick={() => handleClick(slide?.id)}
                  noPadding={false}
                  noBoxShadow={false}
                  noBorder={true}
                  noBorderRadius={true}
                >
                  <img src={slide?.image} alt="" />
                  <div style={{ width: '100%'}}>
                    <Text
                      as="div"
                      className={"title-tiles vod-tiles watchfree-tiles"}
                    >
                      {slide?.title}
                    </Text>
                    <Text as="p" className="style-p">
                      {slide?.duration} <br />
                      {slide?.uploaded}
                    </Text>
                  </div>
                </Card>
              </div>
            );
          })}
          {/* </Column> */}
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Column xs={12}>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                paddingRight: "58px",
              }}
            >
              <Button primary>Load more</Button>
            </div>
          </Column>
        </Row>
      </Grid>
    </Shell.Body>
  );
};
