import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Text } from "@StixNext/mojito-ui";
import Moment from "react-moment";
import moment from "moment-timezone";

import banner from "../../assets/images/success_pass.jpg";

import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import ReactHtmlParser from "react-html-parser";
import decode from "decode-html";
import { pushEventToData } from "../../utils/Datalayer";

function GroupPassSuccess(props) {
  let passType = localStorage.getItem("pass_type");

  useEffect(() => {
    pushEventToData("thankYou", [
      {
        item_id: props?.transactionDetails?.transactionData?.product?.id,
        item_name: props?.transactionDetails?.transactionData?.product?.title
          ?.replace(/(<([^>]+)>)/gi, "")
          ?.replace(/(?:&nbsp;|amp;)/g, ""),
        currency: "SGD",
        price: props?.transactionDetails?.transactionData?.product?.price,
        quantity: 1,
        expiry: props.transactionDetails?.transactionData?.product
          .isFixedExpiryDate
          ? moment(
              new Date(
                props.transactionDetails?.transactionData?.product?.expiresOn
              )
            ).format("DD MMM YYYY hh:mm:ss a")
          : moment(
              moment(new Date()).add(
                `${props.transactionDetails?.transactionData?.product?.validityInMonths}`,
                "months"
              )
            ).format("DD MMM YYYY hh:mm:ss a"),
      },
    ]);
  }, []);
  return (
    <>
      <Grid>
        <div className="success_section">
          <Row>
            <Column xl={6} xs={12}>
              <Row style={{ paddingBottom: "5px" }}>
                <img
                  src={banner}
                  alt="icon"
                  style={{ width: "100%", height: "auto" }}
                />
              </Row>
            </Column>
            <Column xl={6} xs={12}>
              <Row style={{ paddingBottom: "5px" }}>
                <Text as="h4" className="success_txt">
                  Thank you
                </Text>
                <Text as="h5" className="redeem_txt">
                  You have redeemed your{" "}
                  {props.transactionDetails.transactionData &&
                  !!props.transactionDetails.transactionData.product
                    .isSeasonPass &&
                  props.transactionDetails.transactionData.product
                    .isSeasonPass === "true"
                    ? "All Access Pass for"
                    : props.transactionDetails.transactionData.product.sessionIds.split(
                        ","
                      ).length === 1
                    ? "Single Access Pass for"
                    : ""}{" "}
                  {props.transactionDetails.transactionData &&
                  props.transactionDetails.transactionData.product.title
                    ? ReactHtmlParser(
                        decode(
                          props.transactionDetails.transactionData.product.title
                            .replace(/(?:\r\n|\r|\n)/g, "<br />")
                            .replace(/(?:<p>|<\/p>)/g, "")
                        )
                      )
                    : ""}
                </Text>
              </Row>
            </Column>
          </Row>
        </div>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupPassSuccess);
