import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import VideoDetail from "./VideoDetail";
import VideoOnDemandDetail from "./VideoOnDemandDetail";
import * as actions from "../actions/Programmes";
import * as playactions from "../actions/VideoOnDemand";
import { displayFilter, formatDate } from "../utils/utils";

function FixedPath(props) {
  const [pathDetail, setPathDetail] = useState(
    localStorage.getItem("pathDetail")
      ? JSON.parse(localStorage.getItem("pathDetail"))
      : {}
  );

  useEffect(() => {
    props.fetchPlayback();
    props.fetchProgrammes();
  }, []);
  useEffect(() => {
    if (props.programmesList.length > 0) {
      let programmeFilter = props.programmesList.filter(
        (programme) => programme.id === props.match.params.id
      );
      if (
        programmeFilter.length > 0 ||
        process.env.REACT_APP_DRYRUN_PROGRAMME_IDS.includes(
          props.match.params.id
        )
      ) {
        setPathDetail({ path: "watch", eventId: props.match.params.id });
        localStorage.setItem(
          "pathDetail",
          JSON.stringify({
            path: "watch",
            eventId: props.match.params.id,
          })
        );
      } else {
        setPathDetail({ path: "playback", eventId: props.match.params.id });
        localStorage.setItem(
          "pathDetail",
          JSON.stringify({
            path: "playback",
            eventId: props.match.params.id,
          })
        );
      }
    } else if (props.videoOnDemandList.length > 0) {
      let playbackFilter = props.videoOnDemandList.filter(
        (programme) => programme.id === props.match.params.id
      );
      if (playbackFilter.length > 0) {
        setPathDetail({ path: "playback", eventId: props.match.params.id });
        localStorage.setItem(
          "pathDetail",
          JSON.stringify({
            path: "playback",
            eventId: props.match.params.id,
          })
        );
      } else {
        setPathDetail({ path: "watch", eventId: props.match.params.id });
        localStorage.setItem(
          "pathDetail",
          JSON.stringify({
            path: "watch",
            eventId: props.match.params.id,
          })
        );
      }
    }
  }, [props.programmesList, props.videoOnDemandList]);
  return (
    <>
      {Object.keys(pathDetail).length > 0 &&
        (pathDetail?.path === "playback" ? (
          <VideoOnDemandDetail id={pathDetail?.eventId} {...props} />
        ) : (
          <VideoDetail id={pathDetail?.eventId} {...props} />
        ))}
    </>
  );
}

const mapStateToProps = (state) => ({
  videoOnDemandList: state.VideoOnDemand.videoOnDemandList,
  programmesList: state.Programmes.programmesList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPlayback: () => dispatch(playactions.fetchPlayback()),
  fetchProgrammes: () => dispatch(actions.fetchProgrammes()),
});
export default connect(mapStateToProps, mapDispatchToProps)(FixedPath);
