import Programmes from "../../assets/json/programes.json";
import Workshop from "../../assets/json/workshop.json";
import About from "../../assets/json/about.json";
import Video from "../../assets/json/video.json";

export const getProgrammes = () => {
  return new Promise(function (resolve, reject) {
    resolve(Programmes);
  });
};
export const getWorkshop = () => {
  return new Promise(function (resolve, reject) {
    resolve(Workshop);
  });
};

export const getAbout = () => {
  return new Promise(function (resolve, reject) {
    resolve(About);
  });
};

export const getVideoOnDemand = () => {
  return new Promise(function (resolve, reject) {
    resolve(Video);
  });
};
