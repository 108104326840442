import React, { useEffect } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import _ from "lodash";
import { Text, Button, RadioButton } from "@StixNext/mojito-ui";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as prodActions from "../../actions/Products";
import * as transActions from "../../actions/Transaction";
import passimage from "../../assets/images/pass_img.jpg";
import loading from "../../assets/images/loading.gif";
import { pushEventToData } from "../../utils/Datalayer";

function SubscribeProduct(props) {
  const handleSelectionChange = (e) => {
    let selectedProduct = props.productDetails.subscriableProductList?.filter(
      (product) => product.id === e.target.id
    );

    props.changeProductValues("selectedProduct", selectedProduct[0]);
    props.changeTransactionValues("productPrice", e.target.value);
  };

  const handleSubscribeClick = () => {
    pushEventToData("addtoCart", [
      {
        item_id: props?.productDetails?.selectedProduct?.id,
        item_name: props?.productDetails?.selectedProduct?.title
          ?.replace(/(<([^>]+)>)/gi, "")
          ?.replace(/(?:&nbsp;|amp;)/g, ""),
        currency: "SGD",
        price: props?.productDetails?.selectedProduct?.price,
        quantity: 1,
      },
    ]);
    props.getMerchantAuthToken(props.transactionDetails.productPrice);
    props.changeProductValues("isSubscriableProduct", true);

    props.changeProductValues("isFreeEvntClicked", false);
    localStorage.setItem("pass_type", "subscription_pass");

    // props.fetchTransaction(props.loginDetails.user, 'true', '', '');
    props.subscribeProduct(
      props.loginDetails.user,
      props.productDetails.selectedProduct
    );

    // props.changeTransactionValues('loader', 'block');
    // props.changeTransactionValues('paymentLoader', 'none');
    // props.changeTransactionValues('transactionStatus', 2);
  };

  useEffect(() => {
    props.changeProductValues("selectedProduct", {});
    props.changeTransactionValues("productPrice", 0);

    props.fetchSubscribableProducts();

    return () => {
      props.changeProductValues("productList", []);
    };
  }, []);

  return (
    <>
      <div
        style={{
          padding: "10px 0px",
        }}
      >
        <Grid>
          <Row>
            <Column xl={5} xs={12}>
              <img className="main_image" src={passimage} alt="icon" />
            </Column>
            <Column
              xl={7}
              xs={12}
              className={"payment-section-text"}
              style={{ flexFlow: "column", alignItems: "flex-start" }}
            >
              <Row>
                <Text as="h4" className="pass_head">
                  Please select your pass
                </Text>
              </Row>
              <Row>
                <div
                  className={
                    props.productDetails.subscriableProductList?.length < 0
                      ? "loader-product-show"
                      : "loader-pro"
                  }
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "190px", height: "auto" }}
                      src={loading}
                      alt="Avatar"
                    />
                  </div>
                </div>
              </Row>

              {props.productDetails?.subscriableProductList.length > 0 ? (
                props.productDetails?.subscriableProductList?.map((o, k) => {
                  return (
                    <Row className="product_section" key={k}>
                      <Column xl={9} className="radio_colummn">
                        <RadioButton
                          id={o.id}
                          name="radio"
                          labelText={o.title}
                          value={o.price}
                          onChange={handleSelectionChange}
                        >
                          {o.description ? (
                            <span className="info_section">
                              {ReactHtmlParser(
                                o.description
                                  .split("<a")
                                  .join('<a target="_blank"')
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                              )}
                            </span>
                          ) : null}
                        </RadioButton>
                      </Column>
                      <Column xl={2} sm={3} className="approx_colummn">
                        <Text as="h6">
                          <strong>{`S$${o.price}.00`}</strong>
                        </Text>
                      </Column>
                    </Row>
                  );
                })
              ) : (
                <Row className="no_prod_row">
                  <Text as="h4" className="no_product_head">
                    There are no subscribable products to show
                  </Text>
                </Row>
              )}

              <Row style={{ width: "100%", justifyContent: "flex-end" }}>
                <Button
                  primary
                  disabled={_.isEmpty(props.productDetails.selectedProduct)}
                  onClick={handleSubscribeClick}
                >
                  Subscribe Now
                </Button>
              </Row>
            </Column>
          </Row>
        </Grid>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  getAuthToken: (token) => dispatch(actions.getAuthToken(token)),

  getMerchantAuthToken: (amount) =>
    dispatch(actions.getMerchantAuthToken(amount)),

  fetchTransaction: (data, clicked, sessionId) =>
    dispatch(transActions.fetchTransaction(data, clicked, sessionId)),

  subscribeProduct: (user, product) =>
    dispatch(transActions.subscribeProduct(user, product)),

  fetchSubscribableProducts: () =>
    dispatch(prodActions.fetchSubscribableProducts()),

  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),

  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeProduct);
