import Worker from "workerize-loader!./nickname_worker"; // eslint-disable-line import/no-webpack-loader-syntax

export default function NicknameWorker() {
  this.worker = Worker();
  this.subscribers = [];
  this.nickname = "";
  this.getWorker = function () {
    return this.worker;
  };

  this.getNickname = function () {
    return this.nickname;
  };

  this.setNickname = function (nickname) {
    this.nickname = nickname;
    this.notifySubscribers(this.nickname);
  };

  this.addNickname = function (nickname) {
    this.nickname = nickname;
    this.notifySubscribers(this.nickname);
  };

  this.notifySubscribers = function (change) {
    this.subscribers.forEach((subscriber) => {
      subscriber(change);
    });
  };

  this.subscribe = function (itemToSubscribe) {
    if (this.subscribers.indexOf(itemToSubscribe) > -1) {
      return;
    }
    this.subscribers.push(itemToSubscribe);
  };

  this.unsubscribe = function (itemToUnsubscribe) {
    this.subscribers = this.subscribers.filter(
      (subscriber) => subscriber !== itemToUnsubscribe
    );
  };
}

export const nicknameWorker = new NicknameWorker();

nicknameWorker.getWorker().addEventListener("message", ({ data }) => {
  if (data.event === "nicknameUpdateLast") {
    nicknameWorker.setNickname(data.nickname);
  }
});
