const init = {
  overviewDetail: {},
};

export const Overview = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_OVERVIEW_VALUE":
      return action.value;
    default:
      return state;
  }
};
