import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Text,
  Row,
  AccountSideBar,
  BouncerContextHook,
} from "@StixNext/mojito-ui";
import AccountDetails from "./AccountDetails";
import PaymentMethod from "./PaymentMethod";
import PassSubscriptions from "./PassSubscriptions";
import BillingHistory from "./BillingHistory";
import Communications from "./Communications";
import * as actions from "../../../actions/Login";
import * as transActions from "../../../actions/Transaction";

function MyAccount(props) {
  const value = BouncerContextHook();
  const { history, location = {} } = props;
  const { pathname = "/" } = location;
  const [pageNo, setPageNo] = useState("1");
  useEffect(() => {
    if (pathname.includes("autorenew")) {
      setPageNo(3);
      getDegitalPass();
    }
  }, []);

  let getDegitalPass = async (e) => {
    setTimeout(() => {
      if (
        !localStorage.getItem("sso_authenticated") ||
        localStorage.getItem("sso_authenticated") === "false"
      ) {
        value.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked"),
          "",
          ""
        );
      }
    }, 500);
  };
  const renderPage = () => {
    switch (pageNo) {
      case 1:
        return <AccountDetails />;
      case 2:
        return <PaymentMethod />;
      case 3:
        return <PassSubscriptions queryparams={props.match} />;
      case 4:
        return <BillingHistory />;
      case 5:
        return <Communications />;
      default:
        return <AccountDetails />;
    }
  };
  return (
    <div className="acc-div">
      <Row>
        <Text className="acc-head">My Account</Text>
      </Row>
      <div className="acc-body">
        <div className="acc-leftbar">
          <AccountSideBar
            pageNo={pageNo}
            items={[
              {
                menu_name: "Account Details",
                onclick: () => setPageNo(1),
              },
              {
                menu_name: "Payment Method",
                onclick: () => setPageNo(2),
              },
              {
                menu_name: "Pass Subscriptions",
                onclick: () => setPageNo(3),
              },
              // {
              //   menu_name: "Billing History",
              //   onclick: () => setPageNo(4),
              // },
              // {
              //   menu_name: "Communications",
              //   onclick: () => setPageNo(5),
              // },
            ]}
          ></AccountSideBar>
        </div>
        <div className="acc-line"></div>
        <div className="acc-right">{renderPage()}</div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTransaction: (data, buttonClicked, sessionId, from) =>
    dispatch(
      transActions.fetchTransaction(data, buttonClicked, sessionId, from)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
