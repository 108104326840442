import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Text, Button, Row, Column, Modal } from "@StixNext/mojito-ui";
import inviteImage from "./../../assets/images/invite-image.png";
import DiscountImage from "../../assets/images/DiscountPop.jpg";
import * as actions from "../../actions/WatchParty";
import * as transActions from "../../actions/Transaction";
import loadingGif from "./../../assets/images/accept_loading.gif";
import moment from "moment";
import Moment from "react-moment";

function DiscountModal(props) {
  return (
    <Modal
      isOpen={props.isDiscountOpen}
      size="large"
      onClose={() => props.setIsDiscountOpen(false)}
    >
      <Modal.Body className={"inviteModal"}>
        <img
          className="Discount-Image-Style"
          src={props?.overviewDetail?.activePopupImage}
        />
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    productDetails: state.Products,
    programmeDetails: state.Programmes,
    watchpartyDetails: state.WatchParty,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeWatchPartyValues: (f, e) =>
    dispatch(actions.changeWatchPartyValues(f, e)),
  checkoutAndJoinRoom: (user, invitation) =>
    dispatch(actions.checkoutAndJoinRoom(user, invitation)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscountModal);
