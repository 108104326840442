import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Text, Button, Row, Column, Modal } from "@StixNext/mojito-ui";
import inviteImage from "./../../assets/images/invite-image.png";
import * as actions from "../../actions/WatchParty";
import * as transActions from "../../actions/Transaction";
import loadingGif from "./../../assets/images/accept_loading.gif";
import moment from "moment";
import Moment from "react-moment";

function InviteModal(props) {
  const [history] = useState(props.loginDetails.history);
  const [earlierInvitation, setEarlierInvitation] = useState([]);
  const [earlierProgramme, setEarlierProgramme] = useState([]);
  const joinRoom = () => {
    props.changeWatchPartyValues("loadingAccept", true);
    props.checkoutAndJoinRoom(props.loginDetails.user, earlierInvitation[0]);
  };

  useEffect(() => {
    if (
      props.watchpartyDetails.canJoinRoom &&
      Object.keys(props.watchpartyDetails.vipSessionDetails).length > 0
    ) {
      history.push(
        `/watch/${props.watchpartyDetails.vipSessionDetails.session}/${props.watchpartyDetails.vipSessionDetails.roomid}`
      );
    }
  }, [
    props.watchpartyDetails.canJoinRoom,
    props.watchpartyDetails.vipSessionDetails,
  ]);

  useEffect(() => {
    if (
      props.programmeDetails.programmesList.length > 0 &&
      props.watchpartyDetails.invitations.length > 0
    ) {
      let invitationSessionIds = props.watchpartyDetails.invitations.map(
        (item) => item.session
      );
      let filteredProgrammeList = props.programmeDetails.programmesList
        .filter((item) => {
          var todayDate = moment(new Date(moment.utc().format()));
          var startSessionDate = moment(
            new Date(
              moment.utc(item.startTime).add(item.duration, "minutes").format()
            )
          );
          return (
            invitationSessionIds.includes(item.id) &&
            todayDate.diff(startSessionDate, "seconds") < 1
          );
        })
        .sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
      if (filteredProgrammeList.length > 0) {
        setEarlierProgramme([filteredProgrammeList[0]]);
        setEarlierInvitation(
          props.watchpartyDetails.invitations.filter(
            (invitation) => invitation.session === filteredProgrammeList[0].id
          )
        );
      }
    }
  }, [
    props.programmeDetails.programmesList,
    props.watchpartyDetails.invitations,
  ]);
  return (
    <Modal isOpen={props.isOpen} size="large" onClose={props.onClose}>
      <Modal.Body className={"inviteModal"}>
        <div>
          <Row>
            <Column>
              {earlierProgramme.length > 0 &&
              !props.watchpartyDetails.errorMsg ? (
                earlierProgramme.map((programme) => {
                  let imageArray = programme.thumbNail
                    ? programme.thumbNail.split(",")
                    : "";
                  let image =
                    imageArray && imageArray.length > 1
                      ? imageArray[1]
                      : imageArray[0];
                  return (
                    <>
                      <div className="inviteImage">
                        <img src={image} width="500px" height="232px" />
                      </div>
                      <Text className="inviteText">
                        You are invited to a Private VIP Room!
                      </Text>
                      <div className="invite_content">
                        <Text className="invitePara">
                          SSOLOUNGE cordially invites you to an exclusive VIP
                          Room for the following show:
                        </Text>

                        <Text className="inviteContent">
                          Show :
                          <span className="show_design">
                            {programme.title
                              .replace(/(<([^>]+)>)/gi, "")
                              .replace(/(?:&nbsp;|amp;)/g, "")}
                          </span>
                        </Text>
                        <Text className="inviteContent">
                          Date :
                          <span className="date_design">
                            <Moment format="Do MMM YYYY">
                              {programme.startTime}
                            </Moment>
                            ,{" "}
                            <Moment format="dddd">{programme.startTime}</Moment>
                          </span>
                        </Text>
                        <Text className="inviteContent">
                          {" "}
                          Time :{" "}
                          <span className="time_design">
                            <Moment format="LT">{programme.startTime}</Moment>{" "}
                            (SGT)
                          </span>
                        </Text>

                        <Text className="invitePara">
                          On Premiere day, you may also access the VIP Room
                          through the show's Live Streaming page.
                        </Text>
                        <Text className="invitePara">
                          You can also view other VIP Room in the{" "}
                          <Link
                            to="/watch"
                            className="invite_watch"
                            onClick={() => {
                              props.changeTransactionValues(
                                "isInviteModalOpen",
                                false
                              );
                            }}
                          >
                            Watch Now
                          </Link>{" "}
                          tab.
                        </Text>
                        <div className="accept-btn">
                          <Button
                            primary
                            onClick={() => {
                              joinRoom();
                              props.onClose();
                            }}
                          >
                            {props.watchpartyDetails.loadingAccept ? (
                              <div className="invite_loading">
                                <img
                                  className="img-fluid"
                                  src={loadingGif}
                                  alt="Avatar"
                                />
                              </div>
                            ) : (
                              "View Now"
                            )}
                          </Button>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : props.watchpartyDetails.errorMsg ? (
                <>
                  <Text className="inviteText">
                    {props.watchpartyDetails.errorMsg}
                  </Text>
                </>
              ) : (
                <>
                  <Text className="inviteText">There are no shows!!</Text>
                </>
              )}
            </Column>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    productDetails: state.Products,
    programmeDetails: state.Programmes,
    watchpartyDetails: state.WatchParty,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeWatchPartyValues: (f, e) =>
    dispatch(actions.changeWatchPartyValues(f, e)),
  checkoutAndJoinRoom: (user, invitation) =>
    dispatch(actions.checkoutAndJoinRoom(user, invitation)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteModal);
