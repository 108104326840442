import React, { useEffect, useState } from "react";
import vote from "../assets/images/like.svg";
import { Likes, BouncerContextHook } from "@StixNext/mojito-ui";
import * as actions from "../actions/Programmes";
import * as userActions from "../actions/UserActivities";
import { connect } from "react-redux";
import { useGlobalWorker } from "../worker";
import { userDetails, numberToString } from "../utils/utils";

function LikeBox(props) {
  const { slide, loginDetails, textcolor, stopChat } = props;
  const { keycloak } = loginDetails;
  const { sessionId = "" } = keycloak;
  const {
    keycloak: { idTokenParsed = {} },
  } = BouncerContextHook();
  const { name = "", sub: uid = "" } = idTokenParsed;
  const session = props.session || slide.id;
  const { reaction_worker, reactions, sendReaction } = useGlobalWorker();

  const onClick = (e) => {
    if (sessionId) {
      e.stopPropagation();
      sendReaction({ name, session, text: 1, uid });
      registerUserLogs("Like", {});
    }
  };

  useEffect(() => {
    reaction_worker.startSessionReaction(session, uid);
  }, [session, uid, reaction_worker, stopChat]);

  const getReactionCount = () => {
    return reactions.filter((item) => item.session == session).length;
  };

  const registerUserLogs = async (eventType, otherEventInfo) => {
    let deviceDetail = await userDetails().then((data) => {
      return data;
    });
    props.createUserLogs(
      {
        ...props.loginDetails.user,
        sessionId: props.loginDetails.keycloak.sessionId,
      },
      deviceDetail,
      {
        ...props.programmeDetails.programme[0],
        eventDetailInfo: JSON.stringify({ ...otherEventInfo }, numberToString),
      },
      eventType
    );
  };

  return (
    <div className="likebox" style={{ color: textcolor }}>
      <Likes
        emoji={<img src={vote} className="contestant_vote_img" />}
        count={getReactionCount()}
        onLikeClick={(e) => onClick(e, slide)}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    programmeDetails: state.Programmes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeProgrammesValues: (f, e) =>
    dispatch(actions.changeProgrammesValues(f, e)),
  changeUserActivityValues: (f, e) =>
    dispatch(userActions.changeUserActivityValues(f, e)),
  createUserLogs: (user, device, session, eventType) =>
    dispatch(userActions.createUserLogs(user, device, session, eventType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LikeBox);
