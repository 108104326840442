import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Text, Button, Checkbox } from "@StixNext/mojito-ui";

function Communications(props) {
  const [details, setDetails] = useState({
    message: `Stay on top of the latest entertainment news and be the first to know
    what events and shows are happening near you. You can choose to get your
    feeds from us, our show organisers or the event venues. Or better yet,
    sign up for all three. You can unsubscribe at any time.`,
    event: "Singapore Symphony Orchestra (SS0)",
    product: "SISTIC.com Pte Ltd",
  });
  return (
    <>
      <div className="acc-details">
        <div className="head">
          <label>Manage My Communications</label>
        </div>
      </div>

      <Text>{details.message}</Text>
      <div className="sub_check check_out">
        {" "}
        <Checkbox
          checked={true}
          labelBg={true}
          onChange={(e) => {}}
        ></Checkbox>{" "}
        <span className="check_span">{details.event}</span>
      </div>
      <div className="sub_check check_out">
        {" "}
        <Checkbox
          checked={true}
          labelBg={true}
          onChange={(e) => {}}
        ></Checkbox>{" "}
        <span className="check_span">{details.product}</span>
      </div>
      <div className="acc-btn">
        <Button primary className="update-btn" onClick={(e) => {}}>
          Update
        </Button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Communications);
