import axios from "axios";
import DeviceDetector from "device-detector-js";

function showVideo(displayStartTime, displayEndTime) {
  let start = new Date(displayStartTime);
  let end = new Date(displayEndTime);
  let current = new Date();
  return current > start && current < end;
}

export function displayFilter(array) {
  return array.filter((item) =>
    showVideo(item.displayStartTime, item.displayEndTime)
  );
}

export function formatDate(date) {
  let options = { year: "numeric", month: "short", day: "numeric" };
  let [month, day, year] = new Date(date)
    .toLocaleDateString(undefined, options)
    .replace(",", "")
    .split(" ");
  return `${day} ${month} ${year}`;
}

export function formatDateTime(date) {
  let [newDate, time] = new Date(date).toLocaleString("en-US").split(",");
  let [hours, minutes, m] = time.split(":");
  let [_, meridiem] = m.split(" ");
  return `${formatDate(newDate)} ${hours}:${minutes} ${meridiem}`;
}

export const userDetails = async () => {
  const deviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(navigator.userAgent);
  let ipAddress = await getIpaddressInfo().then((data) => {
    return data;
  });
  return {
    browserInfo: JSON.stringify(
      {
        browserCodeName: navigator.appCodeName,
        browserName: navigator.appName,
        browserVersion: navigator.appVersion,
        cookiesEnabled: navigator.cookieEnabled,
        platform: navigator.platform,
        userAgent: navigator.userAgent,
      },
      numberToString
    ),
    ipAddress: ipAddress,
    deviceInfo: JSON.stringify(
      {
        ...device,
      },
      numberToString
    ),
  };
};

const getIpaddressInfo = async () => {
  const res = await axios
    .get("https://geolocation-db.com/json/")
    .then((result) => {
      return result.data.IPv4;
    });
  return res;
};

export const numberToString = (key, value) => {
  if (typeof value === "number") {
    return value + "";
  }
  if (typeof value === "boolean") {
    return value ? "true" : "false";
  }

  if (key && Array.isArray(value)) {
    return JSON.stringify(value);
  }

  return value;
};
