import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Chat, BouncerContextHook } from "@StixNext/mojito-ui";
import { useGlobalWorker } from "../worker";
import { db } from "../firebase/utils";
import * as progrmmeActions from "../actions/Programmes";

function PrivateChat(props) {
  const {
    keycloak: { idTokenParsed = {} },
  } = BouncerContextHook();
  const {
    session,
    reservedNames,
    loginEmail,
    blockedNames,
    isEditName,
    roomid,
    shortCodes,
  } = props;
  const { name = "", sub: uid = "" } = idTokenParsed;
  const {
    message_worker,
    messages,
    sendMessage,
    nickname_worker,
    nickname,
    sendNickname,
  } = useGlobalWorker(session, uid);
  const [defaultNickname, setDefaultNickname] = useState("");
  const [privateMessages, setPrivateMessages] = useState([]);
  let reservedDetails = [
    { email: "vishnu@smitiv.co", nickname: "sistic admin" },
  ];
  useEffect(() => {
    const userDetail = reservedDetails.find(
      (element) => element.email == loginEmail
    );
    setDefaultNickname(userDetail?.nickname);
  }, [loginEmail]);

  useEffect(() => {
    console.log("messages", messages);
    props.fetchShortCodeProperties();
    let filterPrivateMsgs = messages.filter(
      (item) => item.private && item.roomid && item.roomid === roomid
    );
    setPrivateMessages(filterPrivateMsgs);
  }, [messages]);

  useEffect(() => {
    props.stopChat
      ? message_worker.stopSessionChat()
      : message_worker.startSessionChat(session, roomid, uid);

    props.stopChat
      ? nickname_worker.stopSessionNickname()
      : nickname_worker.startSessionNickname(session, uid);
  }, [session, roomid, uid, message_worker, nickname_worker, props.stopChat]);

  const onSaveClick = (nickName) => {
    sendNickname(nickName);
  };

  useEffect(() => {
    console.log("messages useEffect", messages);
    props.fetchShortCodeProperties();
    let filterPrivateMsgs = messages.filter(
      (item) => item.private && item.roomid && item.roomid === roomid
    );
    setPrivateMessages(filterPrivateMsgs);
    return () => {
      message_worker.stopSessionChat();
    };
  }, []);

  return (
    <div className="chat-container">
      <Chat
        user={{ uid, name }}
        messageList={privateMessages}
        sendMessage={(e) => {
          sendMessage({
            name: defaultNickname || nickname.nickname,
            session,
            roomid,
            text: e,
            private: true,
            uid,
          });
        }}
        loadCount={10}
        color="black"
        bgColor="white"
        onSaveNickname={onSaveClick}
        nicknamePresent={nickname.nickname || defaultNickname ? true : false}
        AnyError={nickname.error}
        reservedNames={reservedNames ? reservedNames : ""}
        blockedNames={blockedNames}
        shortCodes={shortCodes}
        onEditName={isEditName}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    programmeDetails: state.Programmes,
    loginDetails: state.Login,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchShortCodeProperties: () =>
    dispatch(progrmmeActions.fetchShortCodeProperties()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateChat);
