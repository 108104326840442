import { getWorkshop, getProgrammes } from "./commonapi/mockData";
import { api } from "./commonapi/apiPaths";
import { changeProductValues } from "./Products";

export const changeProgrammesValues = (field, value) => {
  return {
    type: "UPDATE_PROGRAMMES_VALUE",
    field,
    value,
  };
};

export const fetchProgrammes = () => {
  return (dispatch) => {
    return fetch(api.fetchProgrammes)
      .then((res) => res.json())
      .then((value) => {
        dispatch(
          changeProgrammesValues(
            "programmesList",
            Array.isArray(value.data) ? value.data : []
          )
        );
      })
      .catch((err) => err);
  };
};

export const fetchProgrammeDetail = (progammeId) => {
  const inPreviewMode = localStorage.getItem("inPreviewMode");
  return (dispatch) => {
    return fetch(
      api.fetchProgrammes +
        (inPreviewMode === "true" ? "&previewMode=true" : "")
    )
      .then((res) => res.json())
      .then((value) => {
        let programmeDetail = value.data.filter(function (item) {
          return item.id === progammeId;
        });
        dispatch(changeProductValues("videoDetailId", progammeId));
        dispatch(changeProgrammesValues("programme", programmeDetail));
      })
      .catch((err) => {});
  };
};
export const setDateSelected = (date) => {
  return (dispatch) => {
    dispatch(changeProgrammesValues("dateSelected", date));
  };
};

export const fetchDryRunProgrammeDetail = (progammeId) => {
  return (dispatch) => {
    getProgrammes()
      .then((data) => {
        let programmeDetail = data.filter(function (item) {
          return item.id === progammeId;
        });
        dispatch(changeProgrammesValues("programme", programmeDetail));
      })
      .catch((err) => {});
  };
};

export const fetchChatProperties = () => {
  return (dispatch) => {
    return fetch(api.fetchChatProperties)
      .then((res) => res.json())
      .then((value) => {
        dispatch(
          changeProgrammesValues("chatProperties", value.data[0].reservedNames)
        );
        dispatch(
          changeProgrammesValues("blockedNames", value.data[0].blockedNames)
        );
      })
      .catch((err) => err);
  };
};

export const fetchShortCodeProperties = () => {
  return (dispatch) => {
    return fetch(api.fetchShortCodeProperties)
      .then((res) => res.json())
      .then((value) => {
        dispatch(changeProgrammesValues("shortCodeList", value.data));
      })
      .catch((err) => err);
  };
};
