import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Text, Button, DropInPayment, RadioButton } from "@StixNext/mojito-ui";

import passimage from "../../assets/images/pass_img.jpg";
import loading from "../../assets/images/loading.gif";

import { theme } from "./../../Theme";
import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import * as prodActions from "../../actions/Products";
import ReactHtmlParser from "react-html-parser";
import { pushEventToData } from "../../utils/Datalayer";

function BuySeason(props) {
  const [open, setOpen] = useState(true);
  useEffect(() => {
    props.changeProductValues("selectedProduct", {});
    props.changeTransactionValues("productPrice", 0);
    props.fetchProducts({}, "seasonPass", false);

    return () => {
      props.changeProductValues("productList", []);
    };
  }, []);
  return (
    <>
      <div
        style={{
          padding: "10px 0px",
        }}
      >
        <Grid>
          <Row>
            <Column xl={5} xs={12}>
              <img className="main_image" src={passimage} alt="icon" />
            </Column>
            <Column
              xl={7}
              xs={12}
              className={"payment-section-text"}
              style={{ flexFlow: "column", alignItems: "flex-start" }}
            >
              <Row>
                <Text as="h4" className="pass_head">
                  Please select your pass
                </Text>
              </Row>
              <div
                className={
                  props.productDetails.productLoading
                    ? "loader-product-show"
                    : "loader-pro"
                }
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "190px", height: "auto" }}
                    src={loading}
                    alt="Avatar"
                  />
                </div>
              </div>
              {props.productDetails.productList &&
                props.productDetails.productList.length > 0 &&
                !props.productDetails.productLoading &&
                props.productDetails.productList.map((o, k) => {
                  if (
                    k === 0 &&
                    ((Object.keys(props.productDetails.selectedProduct).length >
                      0 &&
                      props.productDetails.selectedProduct.id !== o.id) ||
                      !Object.keys(props.productDetails.selectedProduct)
                        .length > 0) &&
                    ((o.isFreeEvent && o.isFreeEvent === false) ||
                      !o.isFreeEvent)
                  ) {
                    let filteredProduct =
                      props.productDetails.productList.filter(function (item) {
                        return item.id === o.id;
                      })[0];
                    props.changeProductValues(
                      "selectedProduct",
                      filteredProduct
                    );
                    props.changeProductValues(
                      "isSubscriableProduct",
                      filteredProduct.isSubscribableProduct
                    );
                    props.changeTransactionValues("productPrice", o.price);
                  }
                  return (
                    <>
                      <Row className="product_section" key={k}>
                        <Column xl={8} sm={9}>
                          <RadioButton
                            className="season_radio"
                            labelText={o.title}
                            value={o.price}
                            name="radio"
                            id={o.id}
                            checked={k === 0 && true}
                            onChange={(e) => {
                              var selectedProduct =
                                props.productDetails.productList.filter(
                                  function (item) {
                                    return item.id === e.target.id;
                                  }
                                );
                              props.changeProductValues(
                                "selectedProduct",
                                selectedProduct[0]
                              );
                              props.changeProductValues(
                                "isSubscriableProduct",
                                selectedProduct[0].isSubscribableProduct
                              );
                              props.changeTransactionValues(
                                "productPrice",
                                e.target.value
                              );
                            }}
                          >
                            {o.description ? (
                              <span className="info_section">
                                {ReactHtmlParser(
                                  o.description.replace(
                                    /(?:\r\n|\r|\n)/g,
                                    "<br />"
                                  )
                                )}
                              </span>
                            ) : null}
                          </RadioButton>
                        </Column>
                        <Column xl={2} sm={3} className="pass_price">
                          <Text as="h6">
                            <strong>{`S$${o.price}`}</strong>
                          </Text>
                        </Column>
                      </Row>
                    </>
                  );
                })}
              <Row style={{ width: "100%", justifyContent: "flex-end" }}>
                <Button
                  className="buy_btn"
                  primary
                  disabled={
                    props.transactionDetails.productPrice ? false : true
                  }
                  onClick={(e) => {
                    pushEventToData("addtoCart", [
                      {
                        item_id: props?.productDetails?.selectedProduct?.id,
                        item_name: props?.productDetails?.selectedProduct?.title
                          ?.replace(/(<([^>]+)>)/gi, "")
                          ?.replace(/(?:&nbsp;|amp;)/g, ""),
                        currency: "SGD",
                        price: props?.productDetails?.selectedProduct?.price,
                        quantity: 1,
                      },
                    ]);
                    props.getMerchantAuthToken(
                      props.transactionDetails.productPrice
                    );
                    props.changeTransactionValues("loader", "block");
                    props.changeTransactionValues("paymentLoader", "none");
                    props.changeTransactionValues("transactionStatus", 2);
                  }}
                >
                  {props.productDetails.isSubscriableProduct
                    ? "Subscribe Now"
                    : "Buy Now"}
                </Button>
              </Row>
            </Column>
          </Row>
        </Grid>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  getMerchantAuthToken: (amount) =>
    dispatch(actions.getMerchantAuthToken(amount)),
  fetchTransaction: (data, clicked, sessionId) =>
    dispatch(transActions.fetchTransaction(data, clicked, sessionId)),
  fetchProducts: (programmeId, passType, freeEventClciked) =>
    dispatch(
      prodActions.fetchProducts(programmeId, passType, freeEventClciked)
    ),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuySeason);
