import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Text, TransactionItems } from "@StixNext/mojito-ui";
import PrinterTop from "../../../assets/images/icons/print_image1.svg";
import PrinterMiddle from "../../../assets/images/icons/print_image2.svg";
import PrinterBottom from "../../../assets/images/icons/print_image3.svg";
import Arrow from "../../../assets/images/icons/upper_arrow.svg";

function BillingHistory(props) {
  const [detail, setPageNo] = useState("1");
  return (
    <>
      <div className="acc-details" style={{ position: "relative" }}>
        <div className="head">
          <label>View All My Previous Orders</label>
        </div>
      </div>
      <div className="printer">
        <div className="printer-div">
          <img src={PrinterTop} />
          <img src={PrinterMiddle} />
          <img src={PrinterBottom} />
        </div>
        <div className="expand">
          <Text>Expand All</Text>
        </div>
      </div>
      <div className="underline"></div>
      <TransactionItems
        items={[
          {
            reference_no: "Lv7ugFPXRa",
            date_of_purchase: "Thu, 13 Sep 2021 10:44 AM",
            purchased_with: "Mastercard",
            purchased_from: "https://lounge.sso.org.sg",
            total_amount: "SGD 30.00",
            payment_details: [
              {
                no: "1",
                item: "12M Premium All-Access Pass",
                quantity: "1",
                unit_price: "SGD 30.00",
                booking_fees: "SGD 0.00",
                sub_total: "SGD 30.00",
              },
            ],
          },
          {
            reference_no: "Lv7ugFPXRa",
            date_of_purchase: "Thu, 13 Sep 2021 10:44 AM",
            purchased_with: "Mastercard",
            purchased_from: "https://lounge.sso.org.sg",
            total_amount: "SGD 30.00",
            payment_details: [
              {
                no: "1",
                item: "12M Premium All-Access Pass",
                quantity: "1",
                unit_price: "SGD 30.00",
                booking_fees: "SGD 0.00",
                sub_total: "SGD 30.00",
              },
            ],
          },
        ]}
      ></TransactionItems>

      {/* <>
          <div className="upper-arrow">
            <img src={Arrow} />
            <img src={Arrow} />
          </div>
          <div className="previous-order-cover">
            <div className="order-box">
              <table className="order-box-table">
                <tr>
                  <td>
                    {" "}
                    <Text>Reference No.</Text>
                  </td>
                  <td>Lv7ugFPXRa</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Text>Date of Purchase</Text>
                  </td>
                  <td>Thu, 13 Sep 2021 10:44 AM</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Text>Purchased With</Text>
                  </td>
                  <td>Mastercard</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Text>Purchased From</Text>
                  </td>
                  <td>https://lounge.sso.org.sg</td>
                </tr>
              </table>

              <table className="payment-table">
                <tr>
                  <th>No.</th>
                  <th>Item</th>
                  <th>Qty</th>
                  <th>Unit Price</th>
                  <th>Booking Fees</th>
                  <th>Subtotal</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>12M Premium All-Access Pass</td>
                  <td>1</td>
                  <td>SGD 30.00</td>
                  <td>SGD 0.00</td>
                  <td>SGD 30.00</td>
                </tr>
                <tr className="order-total">
                  <td colSpan={2}>TOTAL (including GST)</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>SGD 30.00</td>
                </tr>
              </table>
            </div>
          </div>
        </>
        <>
          <div className="upper-arrow">
            <img src={Arrow} />
            <img src={Arrow} />
          </div>
          <div className="previous-order-cover">
            <div className="order-box">
              <table className="order-box-table">
                <tr>
                  <td>
                    {" "}
                    <Text>Reference No.</Text>
                  </td>
                  <td>Lv7ugFPXRa</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Text>Date of Purchase</Text>
                  </td>
                  <td>Thu, 13 Sep 2021 10:44 AM</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Text>Purchased With</Text>
                  </td>
                  <td>Mastercard</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Text>Purchased From</Text>
                  </td>
                  <td>https://lounge.sso.org.sg</td>
                </tr>
              </table>

              <table className="payment-table">
                <tr>
                  <th>No.</th>
                  <th>Item</th>
                  <th>Qty</th>
                  <th>Unit Price</th>
                  <th>Booking Fees</th>
                  <th>Subtotal</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>12M Premium All-Access Pass</td>
                  <td>1</td>
                  <td>SGD 30.00</td>
                  <td>SGD 0.00</td>
                  <td>SGD 30.00</td>
                </tr>
                <tr className="order-total">
                  <td colSpan={2}>TOTAL (including GST)</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>SGD 30.00</td>
                </tr>
              </table>
            </div>
          </div>
        </>
        <>
          <div className="upper-arrow">
            <img src={Arrow} />
            <img src={Arrow} />
          </div>
          <div className="previous-order-cover">
            <div className="order-box">
              <table className="order-box-table">
                <tr>
                  <td>
                    {" "}
                    <Text>Reference No.</Text>
                  </td>
                  <td>Lv7ugFPXRa</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Text>Date of Purchase</Text>
                  </td>
                  <td>Thu, 13 Sep 2021 10:44 AM</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Text>Purchased With</Text>
                  </td>
                  <td>Mastercard</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Text>Purchased From</Text>
                  </td>
                  <td>https://lounge.sso.org.sg</td>
                </tr>
              </table>

              <table className="payment-table">
                <tr>
                  <th>No.</th>
                  <th>Item</th>
                  <th>Qty</th>
                  <th>Unit Price</th>
                  <th>Booking Fees</th>
                  <th>Subtotal</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>12M Premium All-Access Pass</td>
                  <td>1</td>
                  <td>SGD 30.00</td>
                  <td>SGD 0.00</td>
                  <td>SGD 30.00</td>
                </tr>
                <tr className="order-total">
                  <td colSpan={2}>TOTAL (including GST)</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>SGD 30.00</td>
                </tr>
              </table>
            </div>
          </div>
        </>
        <>
          <div className="upper-arrow">
            <img src={Arrow} />
            <img src={Arrow} />
          </div>
          <div className="previous-order-cover">
            <div className="order-box">
              <table className="order-box-table">
                <tr>
                  <td>
                    {" "}
                    <Text>Reference No.</Text>
                  </td>
                  <td>Lv7ugFPXRa</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Text>Date of Purchase</Text>
                  </td>
                  <td>Thu, 13 Sep 2021 10:44 AM</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Text>Purchased With</Text>
                  </td>
                  <td>Mastercard</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Text>Purchased From</Text>
                  </td>
                  <td>https://lounge.sso.org.sg</td>
                </tr>
              </table>

              <table className="payment-table">
                <tr>
                  <th>No.</th>
                  <th>Item</th>
                  <th>Qty</th>
                  <th>Unit Price</th>
                  <th>Booking Fees</th>
                  <th>Subtotal</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>12M Premium All-Access Pass</td>
                  <td>1</td>
                  <td>SGD 30.00</td>
                  <td>SGD 0.00</td>
                  <td>SGD 30.00</td>
                </tr>
                <tr className="order-total">
                  <td colSpan={2}>TOTAL (including GST)</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>SGD 30.00</td>
                </tr>
              </table>
            </div>
          </div>
        </> */}
      <div className="bill-cover"></div>
      <div class="pagination">
        <a href="#">&lt;</a>
        <a class="active" href="#">
          1
        </a>
        <a href="#">2</a>
        <a href="#">3</a>
        <a href="#">4</a>
        <a href="#">...</a>
        <a href="#">20</a>
        <a href="#">&gt;</a>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BillingHistory);
