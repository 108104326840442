import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Text,
  Button,
  DropInPayment,
  Checkbox,
  Alert,
} from "@StixNext/mojito-ui";

import main from "../../assets/images/main.jpg";
import loading from "../../assets/images/loading.gif";

import { theme } from "./../../Theme";
import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import * as billingActions from "../../actions/Billing";
import * as prodActions from "../../actions/Products";
import ReactHtmlParser from "react-html-parser";
import { pushEventToData } from "../../utils/Datalayer";

function Transaction(props) {
  // const [promoter, setPromoter] = useState(false);
  // const [venue, setVenue] = useState(false);
  // const [sistic, setSistic] = useState(false);
  const [braintreeError, setBraintreeError] = useState({});
  let passType = localStorage.getItem("pass_type");

  const isSubscriableProduct = props.productDetails.isSubscriableProduct;

  const pushEvent = () => {
    pushEventToData(isFreeEvent ? "proceedRegistration" : "proceedPayment", [
      {
        item_id: props?.productDetails?.selectedProduct?.id,
        item_name: props?.productDetails?.selectedProduct?.title
          ?.replace(/(<([^>]+)>)/gi, "")
          ?.replace(/(?:&nbsp;|amp;)/g, ""),
        currency: "SGD",
        price: props?.productDetails?.selectedProduct?.price,
        quantity: 1,
      },
    ]);
  };

  const isFreeEvent =
    props.productDetails.isFreeEvntClicked ||
    (Object.keys(props.productDetails.selectedProduct).length > 0 &&
      props.productDetails.selectedProduct.isFreeEvent);
  const hanldePayment = (cardPayload) => {
    let subscription = {
      promoter: props.transactionDetails.promoter,
      venue: props.transactionDetails.venue,
      sistic: props.transactionDetails.sistic,
    };
    props.subscribeNewsletter(props.loginDetails.user, subscription);
    if (isFreeEvent) {
      props.registerFreeEvent(
        props.loginDetails.user,
        props.productDetails.selectedProduct,
        subscription
      );
    } else if (props.productDetails.isSubscriableProduct) {
      // if card is new, save card info to my-account/payment-methods
      console.log(cardPayload);
      // if (props.billingDetails.paymentMethods.length > 0) {
      //   const isCardNew = props.billingDetails.paymentMethods.find(
      //     (pm) => pm.last4 !== cardPayload.details.lastFour
      //   );

      //   if (isCardNew) {
      //     const newPaymentMethod = {
      //       id: Math.floor(Math.random() * 100),
      //       cardType: cardPayload.details.cardType,
      //       last4: cardPayload.details.lastFour,
      //       expiredIn: `${cardPayload.details.expirationMonth}/${cardPayload.details.expirationYear}`,
      //     };
      //     props.changeBillingValues("paymentMethods", newPaymentMethod);
      //   }
      // } else {
      //   const newPaymentMethod = {
      //     id: Math.floor(Math.random() * 100),
      //     cardType: cardPayload.details.cardType,
      //     last4: cardPayload.details.lastFour,
      //     expiredIn: `${cardPayload.details.expirationMonth}/${cardPayload.details.expirationYear}`,
      //   };
      //   props.changeBillingValues("paymentMethods", newPaymentMethod);
      // }

      props.createSubscription(
        cardPayload,
        props.loginDetails.user,
        props.productDetails.selectedProduct,
        subscription,
        true
      );
      props.fetchProducts({}, "", false);
    } else {
      props.checkoutTransaction(
        cardPayload,
        props.loginDetails.user,
        props.productDetails.selectedProduct,
        subscription,
        true
      );
    }
  };
  const transactionError = (err) => {
    console.log("erro", err);
    setBraintreeError(err);
  };

  useEffect(() => {
    let discountPassRedemption = localStorage.getItem("discountPassRedemption");
    if (
      discountPassRedemption === "true" &&
      props.productDetails.passcodeValidationMessage === "Valid Passcode"
    ) {
      pushEventToData("addtoCart", [
        {
          item_id: props?.productDetails?.selectedProduct?.id,
          item_name: props?.productDetails?.selectedProduct?.title
            ?.replace(/(<([^>]+)>)/gi, "")
            ?.replace(/(?:&nbsp;|amp;)/g, ""),
          currency: "SGD",
          price: props?.productDetails?.selectedProduct?.price,
          quantity: 1,
        },
      ]);
    }
  }, []);

  return (
    <div className="transaction_body">
      <div>
        <Row>
          <Column xl={12} xs={12}>
            <Text as="h4" className="transaction_header">
              You are{" "}
              {(isSubscriableProduct && "buying ") ||
                (isFreeEvent ? "registering for " : "buying ")}{" "}
              {isSubscriableProduct ||
                (passType === "singlepass" &&
                !props.productDetails.isBundleEvent
                  ? isFreeEvent
                    ? "Free Single Access Pass"
                    : "Single Access Pass"
                  : "")}{" "}
              {!props.productDetails.isBundleEvent && <br />}
              {ReactHtmlParser(
                props.productDetails.selectedProduct.title.replace(
                  /(?:\r\n|\r|\n|<p>|<\/p>)/g,
                  ""
                )
              )}{" "}
              -
              {isFreeEvent
                ? props.productDetails.selectedProduct.isSeasonPass
                  ? " Free"
                  : " Free Event"
                : `S$${props.transactionDetails.productPrice}`}
            </Text>
          </Column>
        </Row>
      </div>
      {/* disclaimer */}
      {isSubscriableProduct && (
        <Alert
          variant="info"
          title="Auto-Renewal Subscription"
          body={`Your subscription will be automatically renewed every 12 months,
        saving you the hassle of renewing the pass after it expires .
        However, this can be turned off at any time under My Account.`}
        />
      )}
      {isFreeEvent ? null : (
        <>
          <div
            className="loader-pro"
            style={{
              width: "100%",
              display: props.transactionDetails.loader,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <img
                className="img-fluid"
                style={{ width: "190px", height: "auto" }}
                src={loading}
                alt="Avatar"
              />
            </div>
          </div>
          <div
            className="dropPayment"
            style={{
              width: "100%",
              display: props.transactionDetails.paymentLoader,
            }}
          ></div>
        </>
      )}
      <div className={"subcription adjustment"}>
        <div className="subscribe">Subscription </div>
        <p>
          I wish to receive marketing information by email from, and agree to
          the collection, use and disclosure of my personal data for such
          marketing by:
        </p>
        <div className="sub_check check_out">
          {" "}
          <Checkbox
            id="promoter"
            checked={props.transactionDetails.promoter}
            labelBg={true}
            onChange={(e) => {
              props.changeTransactionValues("promoter", e.target.checked);
            }}
          ></Checkbox>{" "}
          <span className="check_span">
            Promoter(s) and their marketing agents for the event(s) purchased
          </span>
        </div>
        <div className="sub_check check_inner">
          {" "}
          <Checkbox
            id="promoter"
            labelBg={true}
            checked={props.transactionDetails.promoter}
            onChange={(e) => {
              props.changeTransactionValues("promoter", e.target.checked);
            }}
          ></Checkbox>{" "}
          <span className="check_span">Singapore Symphony Orchestra</span>
        </div>
        <div className="sub_check check_out">
          <Checkbox
            id="venue"
            labelBg={true}
            checked={props.transactionDetails.venue}
            onChange={(e) => {
              props.changeTransactionValues("venue", e.target.checked);
            }}
          ></Checkbox>{" "}
          <span className="check_span">
            Venue Owner(s) / Manager(s) and their marketing agents for the
            event(s) purchased
          </span>
        </div>
        <div className="sub_check check_inner">
          <Checkbox
            id="venue"
            labelBg={true}
            checked={props.transactionDetails.venue}
            onChange={(e) => {
              props.changeTransactionValues("venue", e.target.checked);
            }}
          ></Checkbox>{" "}
          <span className="check_span">SISTIC.com Pte Ltd</span>
        </div>
        <div className="sub_check check_out">
          <Checkbox
            id="sistic"
            labelBg={true}
            checked={props.transactionDetails.sistic}
            onChange={(e) => {
              props.changeTransactionValues("sistic", e.target.checked);
            }}
          ></Checkbox>{" "}
          <span className="check_span">
            SISTIC and its marketing agents for the event(s) purchased and other
            sales, promotions, discounts, contests, or events by SISTIC
          </span>
        </div>
        <p>
          (Note: You may unsubscribe by unchecking the options above or by
          updating your preferences in your SISTIC profile at any time. No
          marketing materials will be sent by any party whose option above is
          unchecked)
        </p>
      </div>
      <div className={"drop-in-payment adjustment"}>
        {props.transactionDetails.showDropinComponent &&
        (isFreeEvent ||
          (props.transactionDetails?.amountbcmetadata &&
            Object.keys(props.transactionDetails.amountbcmetadata).length > 0 &&
            props.transactionDetails.amountbcmetadata.totalamount)) ? (
          <>
            <DropInPayment
              authorizationToken={props.loginDetails.authToken}
              handlePaymentMethod={hanldePayment}
              onError={transactionError}
              buttonText={
                isFreeEvent ? "Proceed with Registration" : "Proceed to Payment"
              }
              pushDataLayerEvent={pushEvent}
              isFreeEvent={isFreeEvent}
              transactionFailed={
                props.transactionDetails.transactionSuccessFlag
              }
              classProp="dropPayment"
              showConsent={true}
              amount={props.transactionDetails.amountbcmetadata.totalamount}
              checkedCheckbox={props.transactionDetails.checkconsent}
              isThreeDSecure={true}
              userInfo={props.loginDetails.user}
              loaderFunction={() => {
                props.changeTransactionValues("loader", "none");
                props.changeTransactionValues("paymentLoader", "block");
              }}
              onChange={(e) => {
                props.changeTransactionValues("checkconsent", e.target.checked);
              }}
            />
          </>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
  billingDetails: state.Billing,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (
    cardPayload,
    user,
    product,
    subscription,
    isThreeDSecure
  ) =>
    dispatch(
      transActions.checkoutTransaction(
        cardPayload,
        user,
        product,
        subscription,
        isThreeDSecure
      )
    ),
  createSubscription: (
    cardPayload,
    user,
    product,
    subscription,
    isThreeDSecure
  ) =>
    dispatch(
      transActions.createSubscription(
        cardPayload,
        user,
        product,
        subscription,
        isThreeDSecure
      )
    ),
  registerFreeEvent: (user, product, subscription) =>
    dispatch(transActions.registerFreeEvent(user, product, subscription)),
  fetchProducts: (programmeId, passType, freeEventClciked) =>
    dispatch(
      prodActions.fetchProducts(programmeId, passType, freeEventClciked)
    ),
  changeBillingValues: (f, e) =>
    dispatch(billingActions.changeBillingValues(f, e)),
  subscribeNewsletter: (user, subscription) =>
    dispatch(transActions.subscribeNewsletter(user, subscription)),
  fetchNewsletter: (user) => dispatch(transActions.fetchNewsletter(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
