import React, { Suspense, useEffect, useState } from "react";
import { ThemeProvider } from "emotion-theming";
import { BaseStyles } from "@StixNext/mojito-ui";
import Routes from "./routes";
import { theme } from "./Theme";
import "./App.css";
// Import root component
import { Provider } from "react-redux";
import { store } from "./stores/configureStore";
import TagManager from "react-gtm-module";
import { isIE } from "react-device-detect";
import { IncompatibleBrowser } from "./components/IncompatibleBrowser";

const tagManagerArgs = {
  gtmId: `${process.env.REACT_APP_GTMID}`,
};
TagManager.initialize(tagManagerArgs);

if (process.env.REACT_APP_NODE_ENV == "production") {
  const tagManagerArgs2 = {
    gtmId: `${process.env.REACT_APP_GTMID2}`,
  };
  TagManager.initialize(tagManagerArgs2);
}

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

const BaseApp = () => {
  const [isOpen, setIsOpen] = useState(isIE ? true : false);
  let onClose = () => {
    setIsOpen(false);
  };
  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme.mojito}>
        <BaseStyles />
        <Provider store={store}>
          <div className="App">
            {isIE ? (
              <IncompatibleBrowser isOpen={isOpen} onClose={onClose} />
            ) : (
              <Routes />
            )}
          </div>
        </Provider>
      </ThemeProvider>
    </Suspense>
  );
};

BaseApp.displayName = "BaseApp";
export default BaseApp;
