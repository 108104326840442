import TagManager from "react-gtm-module";

export const pushEventToData = async (event, eventInfo) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      ecommerce: {
        items: [...eventInfo],
      },
    },
  });
};
