import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Text } from "@StixNext/mojito-ui";
import Moment from "react-moment";
import moment from "moment-timezone";

import banner from "../../assets/images/success_pass.jpg";

import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import ReactHtmlParser from "react-html-parser";
import decode from "decode-html";
import { pushEventToData } from "../../utils/Datalayer";

function TransactionSuccess(props) {
  const [futureBillingDate, setFutureBillingDate] = useState(false);
  let passType = localStorage.getItem("pass_type");
  const isFreeEvent =
    props.productDetails.isFreeEvntClicked ||
    (Object.keys(props.productDetails.selectedProduct).length > 0 &&
      props.productDetails.selectedProduct.isFreeEvent);

  const isSubscriableProduct = props.productDetails.isSubscriableProduct;
  useEffect(() => {
    if (props?.transactionDetails?.subscriptionTransaction?.firstBillingDate) {
      var currentDate = moment(new Date(moment.utc().format()));
      let utcString = new Date(
        props.transactionDetails.subscriptionTransaction.firstBillingDate
      ).toUTCString();
      var firstBillingDate = moment(new Date(moment.utc(utcString).format()));
      if (firstBillingDate.diff(currentDate, "seconds") > 0) {
        setFutureBillingDate(true);
      }
    }
  }, [props.transactionDetails.subscriptionTransaction]);
  useEffect(() => {
    pushEventToData("thankYou", [
      {
        item_id: props?.transactionDetails?.transactionData?.product?.id,
        item_name: props?.transactionDetails?.transactionData?.product?.title
          ?.replace(/(<([^>]+)>)/gi, "")
          ?.replace(/(?:&nbsp;|amp;)/g, ""),
        currency: "SGD",
        price: props?.transactionDetails?.transactionData?.product?.price,
        quantity: 1,
        expiry: props.transactionDetails?.transactionData?.product
          .isFixedExpiryDate
          ? moment(
              new Date(
                props.transactionDetails?.transactionData?.product?.expiresOn
              )
            ).format("DD MMM YYYY hh:mm:ss a")
          : moment(
              moment(new Date()).add(
                `${props.transactionDetails?.transactionData?.product?.validityInMonths}`,
                "months"
              )
            ).format("DD MMM YYYY hh:mm:ss a"),
      },
    ]);
  }, []);
  return (
    <>
      <Grid>
        <Row>
          <img src={banner} alt="icon" className="success_img" />
        </Row>
        <div className="success_section">
          <Row>
            <Column xl={12}>
              <Text as="h6" className="thank_txt">
                Thank you. You have{" "}
                {!!isSubscriableProduct
                  ? props.transactionDetails?.subscriptionTransaction
                      ?.transactions?.length > 0
                    ? "subscribed to"
                    : "activated the following:"
                  : isFreeEvent
                  ? "registered for"
                  : "purchased"}
              </Text>
            </Column>
          </Row>
          <Row style={{ justifyContent: "space-between", width: "100%" }}>
            <Column xl={9} sm={12}>
              <Text as="h6" className="label_txt">
                {passType === "singlepass" &&
                !props.productDetails.isBundleEvent
                  ? isFreeEvent
                    ? "Free Single Access Pass - "
                    : "Single Access Pass - "
                  : ""}
                {props.transactionDetails?.transactionData &&
                props.transactionDetails?.transactionData.product.title
                  ? ReactHtmlParser(
                      decode(
                        props.transactionDetails?.transactionData?.product?.title.replace(
                          /(?:\r\n|\r|\n)/g,
                          "<br />"
                        )
                      )
                    )
                  : ""}
              </Text>
              <Text as="captionblock" className="confirm_section">
                {!isSubscriableProduct && (
                  <span>
                    Pass is valid until{" "}
                    <span style={{ fontWeight: "bold", color: "#000000" }}>
                      <Moment format="DD MMM YYYY HH:mm">
                        {props.transactionDetails?.transactionData?.product
                          .isFixedExpiryDate
                          ? moment(
                              new Date(
                                moment
                                  .utc(
                                    new Date(
                                      props.transactionDetails?.transactionData?.product?.expiresOn
                                    ).toUTCString()
                                  )
                                  .format()
                              )
                            )
                          : moment(
                              new Date(
                                moment.utc(new Date().toUTCString()).format()
                              )
                            ).add(
                              `${props.transactionDetails?.transactionData?.product?.validityInMonths}`,
                              "months"
                            )}
                      </Moment>
                    </span>
                  </span>
                )}

                <br />
                <br />
                <br />
                <span style={{ lineHeight: "20px" }}>
                  {!!isSubscriableProduct &&
                  props.transactionDetails?.subscriptionTransaction
                    ?.transactions.length > 0 ? (
                    "A confirmation e-mail will be sent to you"
                  ) : !!isSubscriableProduct && futureBillingDate ? (
                    <>
                      <span>
                        {"Your payment will be processed on " +
                          moment
                            .utc(
                              new Date(
                                props.transactionDetails?.subscriptionTransaction?.firstBillingDate
                              )
                            )
                            .tz("Asia/Singapore")
                            .format("DD MMM YYYY") +
                          "."}
                      </span>
                      <br />
                      {
                        "An email confirmation will be sent to you upon successful renewal."
                      }
                    </>
                  ) : props.transactionDetails?.transactionData
                      ?.reference_id ? (
                    "A confirmation e-mail will be sent to you within 48 hours"
                  ) : (
                    ""
                  )}
                </span>
              </Text>
            </Column>
            <Column xl={2} className="price_section_web">
              <Text as="h3">
                <strong>
                  {isFreeEvent
                    ? "Free Event"
                    : props.transactionDetails?.transactionData
                    ? "S$" +
                      props.transactionDetails?.transactionData
                        ?.payment_amount +
                      ".00"
                    : ""}
                </strong>
              </Text>
              <span style={{ fontSize: "12px" }}>
                {!!isSubscriableProduct
                  ? props.transactionDetails?.subscriptionTransaction
                      ?.transactions.length > 0
                    ? "Exclude booking fee"
                    : "To be charged Exclude booking fee"
                  : !isFreeEvent && "Exclude booking fee"}
              </span>
            </Column>
          </Row>
          <Row style={{ justifyContent: "space-between", width: "100%" }}>
            {/* <Column size={2}>
              <Text as="captionblock" style={{ fontWeight: "100" }}>
                {props.transactionDetails.transactionData
                  ? "A confirmation e-mail will be sent to you within 48 hours"
                  : ""}
              </Text>
            </Column> */}
            <Column xl={12} className="price_section_mob">
              <Text as="h3">
                <strong>
                  $
                  {props.transactionDetails?.transactionData
                    ? props.transactionDetails?.transactionData?.payment_amount
                    : ""}
                  .00
                </strong>
              </Text>
            </Column>
          </Row>
          {!isSubscriableProduct && (
            <Row>
              <Column xl={12}>
                <Text as="h6" className="thank_txt">
                  Booking Reference
                </Text>
              </Column>
            </Row>
          )}
          <Row>
            {!isFreeEvent &&
              ((!!isSubscriableProduct &&
                props.transactionDetails?.subscriptionTransaction?.transactions
                  ?.length > 0) ||
                props?.transactionDetails?.transactionData?.reference_id) && (
                <Column xl={3} sm={12}>
                  <Row style={{ paddingBottom: "5px" }}>
                    <Text as="Captionblock">Transaction ID</Text>
                  </Row>
                  <Text as="Captionblock">
                    {!!isSubscriableProduct
                      ? props.transactionDetails?.transactionData
                        ? props.transactionDetails?.transactionData?.id
                        : ""
                      : props.transactionDetails?.transactionData
                      ? props.transactionDetails?.transactionData?.reference_id
                      : ""}
                  </Text>
                </Column>
              )}
            {((!!isSubscriableProduct &&
              props.transactionDetails?.subscriptionTransaction?.transactions
                ?.length > 0) ||
              (props.transactionDetails?.transactionData &&
                props.transactionDetails?.transactionData?.reference_id)) && (
              <Column xl={3} sm={12}>
                <Row style={{ paddingBottom: "5px" }}>
                  <Text as="Captionblock">
                    Date Of {isFreeEvent ? "Register" : "Purchase"}
                  </Text>
                </Row>
                <Text as="Captionblock">
                  {props.transactionDetails?.transactionData?.txn_time ? (
                    <Moment
                      parse="DD/MM/YYYY hh:mm:ss a"
                      format="DD MMMM YYYY, h:mm a"
                    >
                      {props.transactionDetails?.transactionData?.txn_time}
                    </Moment>
                  ) : (
                    <Moment
                      parse="DD/MM/YYYY hh:mm:ss a"
                      format="DD MMMM YYYY, h:mm a"
                    >
                      {new Date().toLocaleString("en-SG", {
                        timeZone: "Asia/Singapore",
                      })}
                    </Moment>
                  )}
                </Text>
              </Column>
            )}
            <Column xl={3} sm={12}>
              <Row style={{ paddingBottom: "5px" }}>
                <Text as="Captionblock">Name</Text>
              </Row>
              <Text as="Captionblock" className="tranform_txt">
                {props.loginDetails.keycloak
                  ? `${props.loginDetails.keycloak.tokenParsed.given_name} ${props.loginDetails.keycloak.tokenParsed.family_name}`
                  : ""}
              </Text>
            </Column>
          </Row>
        </div>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionSuccess);
