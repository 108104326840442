import { api } from "./commonapi/apiPaths";
import { headers } from "./commonapi/Headers";
import { ifTokenExpire } from "./commonapi/TokenValidation";
import { changeLoginValues } from "./Login";
import moment from "moment";

export const changeTransactionValues = (field, value) => {
  return {
    type: "UPDATE_TRANSACTION_VALUE",
    field,
    value,
  };
};

export const replaceTransactionValues = (field, value) => {
  return {
    type: "REPLACE_TRANSACTION_VALUE",
    field,
    value,
  };
};

export const fetchTransaction = (user, buttonClicked, sessionId, from) => {
  var header = headers();
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.fetchTnxn, {
        headers: header,
        method: "POST",
        body: JSON.stringify({
          userid: user.userid,
          clientid: process.env.REACT_APP_CLIENTID,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data) {
            var tokenExiration = ifTokenExpire(data);
            if (tokenExiration) {
              dispatch(changeLoginValues("tokenExpired", "Token Expired"));
            } else {
              if (data.count > 0) {
                data.transaction.forEach((pass) => {
                  var currentDate = moment(new Date(moment.utc().format()));
                  let utcString = new Date(
                    pass.product.expiresOn
                  ).toUTCString();
                  var productExpiryDate = moment(
                    new Date(moment.utc(utcString).format())
                  );
                  let activeSubscription = data.recurringData.filter((item) => {
                    return (
                      item.product.id === pass.product.id &&
                      item?.subscriptionresdata?.status === "Active" &&
                      productExpiryDate.diff(currentDate, "seconds") > 0
                    );
                  });
                  let pendingRecurring = data.recurringData.filter((item) => {
                    return (
                      item.product.id === pass.product.id &&
                      item?.subscriptionresdata?.status === "Pending" &&
                      productExpiryDate.diff(currentDate, "seconds") > 0
                    );
                  });
                  pass.product["isAutoRenew"] =
                    activeSubscription.length > 0 || pendingRecurring.length > 0
                      ? true
                      : false;
                  pass["subscriptionId"] =
                    activeSubscription.length > 0
                      ? activeSubscription[0].subscriptionresdata.id
                      : pendingRecurring.length > 0
                      ? pendingRecurring[0].subscriptionresdata.id
                      : "";
                });

                dispatch(
                  changeTransactionValues("userPasses", data.transaction)
                );
                dispatch(
                  changeTransactionValues("recurringData", data.recurringData)
                );
                if (
                  from &&
                  Object.keys(from).length > 0 &&
                  from.from === "checkout"
                ) {
                  let passExist = data.transaction.filter(
                    (trans) =>
                      trans.product && trans.product.id === from.product.id
                  );
                  if (!passExist.length > 0) {
                    setTimeout(() => {
                      dispatch(fetchTransaction(user, false, "", from));
                    }, 2000);
                  } else {
                    dispatch(fetchTransaction(user, false, "", ""));
                  }
                } else {
                  let sessionPassData = data.transaction.filter((trans) => {
                    var currentDate = moment(new Date(moment.utc().format()));
                    let utcString = new Date(
                      trans.product.expiresOn
                    ).toUTCString();
                    var productExpiryDate = moment(
                      new Date(moment.utc(utcString).format())
                    );
                    return (
                      trans.product &&
                      trans.product.isSeasonPass === "true" &&
                      ((trans.product.isFreeEvent &&
                        trans.product.isFreeEvent === "false") ||
                        !trans.product.isFreeEvent) &&
                      productExpiryDate.diff(currentDate, "seconds") > 0
                    );
                  });

                  let sessionFreePassData = data.transaction.filter((trans) => {
                    var currentDate = moment(new Date(moment.utc().format()));
                    let utcString = new Date(
                      trans.product.expiresOn
                    ).toUTCString();
                    var productExpiryDate = moment(
                      new Date(moment.utc(utcString).format())
                    );
                    return (
                      trans.product &&
                      trans.product.isSeasonPass === "true" &&
                      trans.product.isFreeEvent &&
                      trans.product.isFreeEvent === "true" &&
                      productExpiryDate.diff(currentDate, "seconds") > 0
                    );
                  });

                  let singlePassData = data.transaction.filter((trans) => {
                    var currentDate = moment(new Date(moment.utc().format()));
                    let utcString = new Date(
                      trans.product.expiresOn
                    ).toUTCString();
                    var productExpiryDate = moment(
                      new Date(moment.utc(utcString).format())
                    );
                    return (
                      trans.product &&
                      (!trans.product.isSeasonPass ||
                        trans.product.isSeasonPass === "false") &&
                      trans.product.sessionIds &&
                      trans.product.sessionIds !== "N/A" &&
                      trans.product.sessionIds !== null &&
                      productExpiryDate.diff(currentDate, "seconds") > 0
                    );
                  });

                  if (sessionPassData.length > 0) {
                    dispatch(
                      changeTransactionValues(
                        "expireTransactionType",
                        "seasonpass"
                      )
                    );
                    dispatch(
                      changeTransactionValues(
                        "expireTransactionData",
                        sessionPassData
                      )
                    );
                    dispatch(
                      changeTransactionValues("showDropinComponent", false)
                    );
                    dispatch(changeTransactionValues("hideBuyButton", true));
                    dispatch(
                      changeTransactionValues("transactionSuccessFlag", true)
                    );
                    localStorage.setItem("buttonClicked", false);
                  } else {
                    let availableVideoIds = [];
                    if (singlePassData.length > 0) {
                      dispatch(
                        changeTransactionValues(
                          "expireTransactionType",
                          "singlepass"
                        )
                      );
                      dispatch(
                        changeTransactionValues(
                          "expireTransactionData",
                          singlePassData
                        )
                      );
                      singlePassData.map(
                        (o) =>
                          (availableVideoIds = [
                            ...availableVideoIds,
                            ...o.product.sessionIds.split(","),
                          ])
                      );
                      dispatch(
                        changeTransactionValues(
                          "singlePassIds",
                          availableVideoIds
                        )
                      );
                    }
                    if (sessionFreePassData.length > 0) {
                      dispatch(
                        changeTransactionValues("expireTransactionData", [
                          ...singlePassData,
                          ...sessionFreePassData,
                        ])
                      );
                    }
                    if (buttonClicked == "true") {
                      let passtype = localStorage.getItem("pass_type");
                      let isSubscribeBtn =
                        localStorage.getItem("isSubscribeButton");
                      if (
                        passtype === "seasonpass" &&
                        ((isSubscribeBtn && isSubscribeBtn === "false") ||
                          !isSubscribeBtn)
                      ) {
                        dispatch(
                          changeTransactionValues("transactionStatus", 9)
                        );
                        dispatch(
                          changeTransactionValues("showDropinComponent", true)
                        );
                        dispatch(changeTransactionValues("isOpen", true));
                      } else if (
                        passtype === "seasonpass" &&
                        isSubscribeBtn &&
                        isSubscribeBtn === "true"
                      ) {
                        dispatch(
                          changeTransactionValues("transactionStatus", 13)
                        );
                        dispatch(
                          changeTransactionValues("showDropinComponent", true)
                        );
                        dispatch(changeTransactionValues("isOpen", true));
                      } else if (passtype === "seasonfreepass") {
                        dispatch(
                          changeTransactionValues("transactionStatus", 12)
                        );
                        dispatch(
                          changeTransactionValues("showDropinComponent", true)
                        );
                        dispatch(changeTransactionValues("isOpen", true));
                      } else if (passtype === "singlepass") {
                        if (
                          singlePassData.length > 0 &&
                          singlePassData.some((single) =>
                            single.product.sessionIds.includes(sessionId)
                          )
                        ) {
                          dispatch(
                            changeTransactionValues(
                              "showDropinComponent",
                              false
                            )
                          );
                          localStorage.setItem("buttonClicked", false);
                        } else {
                          dispatch(
                            changeTransactionValues("transactionStatus", 8)
                          );
                          dispatch(
                            changeTransactionValues("showDropinComponent", true)
                          );
                          dispatch(changeTransactionValues("isOpen", true));
                        }
                      }
                    }
                  }
                  if (
                    localStorage.getItem("pass_type") === "subscription_pass"
                  ) {
                    dispatch(
                      changeTransactionValues("showDropinComponent", true)
                    );
                    // dispatch(changeTransactionValues("hideBuyButton", false));
                  }
                }
                dispatch(changeTransactionValues("recurringDataFetch", true));
              } else if (data.count == "0") {
                if (
                  from &&
                  Object.keys(from).length > 0 &&
                  from.from === "checkout"
                ) {
                  setTimeout(() => {
                    dispatch(fetchTransaction(user, false, "", from));
                  }, 2000);
                } else {
                  if (buttonClicked == "true") {
                    let passtype = localStorage.getItem("pass_type");
                    let isSubscribeBtn =
                      localStorage.getItem("isSubscribeButton");
                    dispatch(
                      changeTransactionValues(
                        "transactionStatus",
                        passtype === "seasonpass" &&
                          ((isSubscribeBtn && isSubscribeBtn === "false") ||
                            !isSubscribeBtn)
                          ? 9
                          : passtype === "seasonpass" &&
                            isSubscribeBtn &&
                            isSubscribeBtn === "true"
                          ? 13
                          : passtype === "seasonfreepass"
                          ? 12
                          : 8
                      )
                    );
                    dispatch(
                      changeTransactionValues("showDropinComponent", true)
                    );
                    dispatch(changeTransactionValues("isOpen", true));
                  }
                }
                dispatch(changeTransactionValues("recurringDataFetch", true));
              }
              dispatch(changeTransactionValues("fetchtransactiondone", true));
            }
          }
        })
        .catch((err) => {});
    }
  };
};

export const checkoutTransaction = (
  cardPayload,
  user,
  product,
  subscription,
  isThreeDSecure
) => {
  var header = headers();
  const postPayload = cardPayload;
  postPayload.userid = user.userid;
  postPayload.productid = product.id;
  postPayload.amount = product.price;
  postPayload.deviceData = {};
  postPayload.productdesc = product.description;
  postPayload.maid = process.env.REACT_APP_MERCHANT_ACCID;
  postPayload.clientid = process.env.REACT_APP_CLIENTID;
  postPayload.isThreeDSecure =
    isThreeDSecure !== undefined
      ? isThreeDSecure
      : process.env.REACT_APP_ISTHREEDSECURE;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  // postPayload.product = {
  //   ...product,
  //   price: product.price.toString(),
  //   isSeasonPass: product.isSeasonPass
  //     ? product.isSeasonPass.toString()
  //     : "false",
  //   isActive: product.isActive ? product.isActive.toString() : "false",
  // };
  postPayload.product = JSON.parse(
    JSON.stringify(product, (key, value) => {
      if (typeof value === "number") {
        return value + "";
      }
      if (typeof value === "boolean") {
        return value ? "true" : "false";
      }
      if (key && Array.isArray(value)) {
        return JSON.stringify(value);
      }
      return value;
    })
  );
  postPayload.subscription = JSON.parse(
    JSON.stringify(subscription, (key, value) => {
      if (typeof value === "number") {
        return value + "";
      }
      if (typeof value === "boolean") {
        return value ? "true" : "false";
      }
      if (key && Array.isArray(value)) {
        return JSON.stringify(value);
      }
      return value;
    })
  );
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.checkout, {
        headers: header,
        method: "POST",
        body: JSON.stringify(postPayload),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(
              changeTransactionValues("transactionData", data.transaction[0])
            );
            dispatch(changeTransactionValues("isOpen", true));
            dispatch(changeTransactionValues("transactionStatus", 3));
            dispatch(changeTransactionValues("checkconsent", false));
            localStorage.setItem("buttonClicked", false);
            localStorage.setItem("subscribeButtonClicked", false);
            localStorage.removeItem("productId");
            setTimeout(() => {
              dispatch(
                fetchTransaction(user, false, "", {
                  from: "checkout",
                  product: product,
                })
              );
            }, 2000);
          }
        })
        .catch((err) => {
          var tokenExiration = ifTokenExpire(err);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(changeTransactionValues("transactionSuccessFlag", false));
            dispatch(changeTransactionValues("checkconsent", false));
            dispatch(changeTransactionValues("isOpen", true));
            dispatch(changeTransactionValues("transactionStatus", 4));
          }
        });
    }
  };
};

export const grouppassTransaction = (user, passid) => {
  var header = headers();
  const postPayload = {};
  postPayload.code = passid;
  postPayload.clientid = process.env.REACT_APP_CLIENTID;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  postPayload.isMultiPass = true;
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.gpurchase, {
        headers: header,
        method: "POST",
        body: JSON.stringify(postPayload),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            if (data?.transaction?.length > 0) {
              localStorage.setItem("grouppasslogin", false);
              localStorage.setItem("grouppass", false);
              localStorage.removeItem("grouppassid");
              localStorage.setItem("grouppasscompleted", true);
              dispatch(
                changeTransactionValues("transactionData", data.transaction[0])
              );
              dispatch(changeTransactionValues("transactionStatus", 6));
              // dispatch(changeTransactionValues("transactionSuccessFlag", true));
              dispatch(changeTransactionValues("isOpen", true));
              setTimeout(() => {
                dispatch(
                  fetchTransaction(user, false, "", {
                    from: "checkout",
                    product: data.transaction[0].product,
                  })
                );
              }, 2000);
            } else {
              dispatch(
                changeTransactionValues("transactionSuccessFlag", false)
              );
              dispatch(changeTransactionValues("transactionStatus", 7));
              dispatch(
                changeTransactionValues("gpurchaseFailureMessage", data.message)
              );
              localStorage.setItem("grouppasslogin", false);
              localStorage.setItem("grouppass", false);
              localStorage.removeItem("grouppassid");
              localStorage.setItem("grouppasscompleted", true);
              dispatch(changeTransactionValues("isOpen", true));
            }
          }
        })
        .catch((err) => {
          var tokenExiration = ifTokenExpire(err);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(changeTransactionValues("transactionSuccessFlag", false));
            dispatch(changeTransactionValues("transactionStatus", 7));
            dispatch(changeTransactionValues("gpurchaseFailureMessage", false));
            localStorage.setItem("grouppasslogin", false);
            localStorage.setItem("grouppass", false);
            localStorage.removeItem("grouppassid");
            localStorage.setItem("grouppasscompleted", true);
            dispatch(changeTransactionValues("isOpen", true));
          }
        });
    }
  };
};

export const registerFreeEvent = (user, product, subscription) => {
  var header = headers();
  const postPayload = {};
  postPayload.userid = user.userid;
  postPayload.productid = product.id;
  postPayload.amount = product.price;
  postPayload.deviceData = {};
  postPayload.productdesc = product.description;
  postPayload.playbackBundle = product.playbackBundle;
  postPayload.maid = process.env.REACT_APP_MERCHANT_ACCID;
  postPayload.clientid = process.env.REACT_APP_CLIENTID;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  postPayload.product = JSON.parse(
    JSON.stringify(product, (key, value) => {
      if (typeof value === "number") {
        return value + "";
      }
      if (typeof value === "boolean") {
        return value ? "true" : "false";
      }
      if (key && Array.isArray(value)) {
        return JSON.stringify(value);
      }
      return value;
    })
  );
  postPayload.subscription = JSON.parse(
    JSON.stringify(subscription, (key, value) => {
      if (typeof value === "number") {
        return value + "";
      }
      if (typeof value === "boolean") {
        return value ? "true" : "false";
      }
      return value;
    })
  );
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.registerFreeEvent, {
        headers: header,
        method: "POST",
        body: JSON.stringify(postPayload),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(
              changeTransactionValues("transactionData", data.transaction[0])
            );
            dispatch(changeTransactionValues("transactionStatus", 3));
            dispatch(changeTransactionValues("checkconsent", false));
            localStorage.setItem("buttonClicked", false);

            setTimeout(() => {
              dispatch(
                fetchTransaction(user, false, "", {
                  from: "checkout",
                  product: product,
                })
              );
            }, 2000);
          }
        })
        .catch((err) => {
          var tokenExiration = ifTokenExpire(err);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(changeTransactionValues("transactionSuccessFlag", false));
            dispatch(changeTransactionValues("checkconsent", false));
            dispatch(changeTransactionValues("transactionStatus", 4));
          }
        });
    }
  };
};

export const createSubscription = (
  cardPayload,
  user,
  product,
  subscription,
  isThreeDSecure
) => {
  var header = headers();
  const postPayload = cardPayload;
  postPayload.userid = user.userid;
  postPayload.productid = product.id;
  postPayload.amount = product.price;
  postPayload.deviceData = {};
  postPayload.productdesc = product.description;
  postPayload.maid = process.env.REACT_APP_MERCHANT_ACCID;
  postPayload.clientid = process.env.REACT_APP_CLIENTID;
  postPayload.isThreeDSecure =
    isThreeDSecure !== undefined
      ? isThreeDSecure
      : process.env.REACT_APP_ISTHREEDSECURE;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  // postPayload.product = {
  //   ...product,
  //   price: product.price.toString(),
  //   isSeasonPass: product.isSeasonPass
  //     ? product.isSeasonPass.toString()
  //     : "false",
  //   isActive: product.isActive ? product.isActive.toString() : "false",
  // };
  postPayload.product = JSON.parse(
    JSON.stringify(product, (key, value) => {
      if (typeof value === "number") {
        return value + "";
      }
      if (typeof value === "boolean") {
        return value ? "true" : "false";
      }
      if (key && Array.isArray(value)) {
        return JSON.stringify(value);
      }
      return value;
    })
  );
  postPayload.subscription = JSON.parse(
    JSON.stringify(subscription, (key, value) => {
      if (typeof value === "number") {
        return value + "";
      }
      if (typeof value === "boolean") {
        return value ? "true" : "false";
      }
      return value;
    })
  );
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.recurring, {
        headers: header,
        method: "POST",
        body: JSON.stringify(postPayload),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            let txn_time = new Date().toLocaleString("en-SG", {
              timeZone: "Asia/Singapore",
            });
            let transaction =
              data?.subscription?.transactions?.length > 0
                ? { ...data.subscription.transactions[0], txn_time }
                : { txn_time };
            dispatch(
              changeTransactionValues("transactionData", {
                ...transaction,
                product,
                payment_amount: product.price,
              })
            );
            dispatch(
              changeTransactionValues(
                "subscriptionTransaction",
                data?.subscription ? data.subscription : {}
              )
            );
            dispatch(changeTransactionValues("isOpen", true));
            dispatch(changeTransactionValues("transactionStatus", 3));
            dispatch(changeTransactionValues("checkconsent", false));
            dispatch(changeTransactionValues("renewalDone", true));
            localStorage.setItem("buttonClicked", false);
            localStorage.setItem("subscribeButtonClicked", false);
            localStorage.removeItem("productId");
            setTimeout(() => {
              dispatch(
                fetchTransaction(user, false, "", {
                  from: "checkout",
                  product,
                })
              );
            }, 2000);
          }
        })
        .catch((err) => {
          var tokenExiration = ifTokenExpire(err);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(changeTransactionValues("transactionSuccessFlag", false));
            dispatch(changeTransactionValues("checkconsent", false));
            dispatch(changeTransactionValues("isOpen", true));
            dispatch(changeTransactionValues("transactionStatus", 4));
          }
        });
    }
  };
};

export const cancelSubscription = (user, subscriptionIds, currentPass) => {
  var header = headers();
  const postPayload = {};
  postPayload.client = process.env.REACT_APP_CLIENTID;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  postPayload.subscriptionIds = subscriptionIds;
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.cancelSubscription, {
        headers: header,
        method: "POST",
        body: JSON.stringify(postPayload),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            if (currentPass) {
              currentPass.product.isAutoRenew =
                !currentPass.product.isAutoRenew;
              dispatch(replaceTransactionValues("userPasses", currentPass));
              dispatch(changeTransactionValues("renewalDone", true));
            }

            setTimeout(() => {
              dispatch(fetchTransaction(user, false, "", ""));
            }, 2000);
          }
        })
        .catch((err) => {
          var tokenExiration = ifTokenExpire(err);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(changeTransactionValues("transactionSuccessFlag", false));
            dispatch(changeTransactionValues("checkconsent", false));
            dispatch(changeTransactionValues("isOpen", true));
            dispatch(changeTransactionValues("transactionStatus", 4));
          }
        });
    }
  };
};

export const subscribeProduct = (user, product) => {
  var header = headers();

  const postPayload = {};
  postPayload.userid = user.userid;
  postPayload.productid = product.id;
  postPayload.amount = product.price;
  postPayload.deviceData = {};
  postPayload.productdesc = product.description;
  postPayload.playbackBundle = product.playbackBundle;
  postPayload.maid = process.env.REACT_APP_MERCHANT_ACCID;
  postPayload.clientid = process.env.REACT_APP_CLIENTID;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  postPayload.product = JSON.parse(
    JSON.stringify(product, (key, value) => {
      if (typeof value === "number") return value + "";
      else if (typeof value === "boolean") return value ? "true" : "false";
      else if (key && Array.isArray(value)) return JSON.stringify(value);
      return value;
    })
  );

  return (dispatch) => {
    localStorage.setItem("pass_type", "subscription_pass");
    dispatch(changeTransactionValues("loader", "block"));
    dispatch(changeTransactionValues("paymentLoader", "none"));
    dispatch(changeTransactionValues("transactionStatus", 2));
    localStorage.setItem("buttonClicked", false);
    localStorage.setItem("subscribeButtonClicked", false);
    dispatch(changeTransactionValues("showDropinComponent", true));
  };
};

export const subscribeNewsletter = (user, subscriptionDetails) => {
  var header = headers();
  const postPayload = {};
  postPayload.client = process.env.REACT_APP_CLIENTID;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  postPayload.subscriptionDetails = JSON.parse(
    JSON.stringify(subscriptionDetails, (key, value) => {
      if (typeof value === "number") return value + "";
      else if (typeof value === "boolean") return value ? "true" : "false";
      else if (key && Array.isArray(value)) return JSON.stringify(value);
      return value;
    })
  );
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.createNewsletter, {
        headers: header,
        method: "POST",
        body: JSON.stringify(postPayload),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(changeTransactionValues("newsletterSuccessFlag", true));
          }
        })
        .catch((err) => {
          var tokenExiration = ifTokenExpire(err);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(changeTransactionValues("newsletterSuccessFlag", false));
          }
        });
    }
  };
};

export const fetchNewsletter = (user) => {
  var header = headers();
  const postPayload = {};
  postPayload.client = process.env.REACT_APP_CLIENTID;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.fetchNewsletterSubscription, {
        headers: header,
        method: "POST",
        body: JSON.stringify(postPayload),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(
              changeTransactionValues("newsletterDetails", data.response)
            );
            if (data?.response?.length > 0) {
              if (
                Object.keys(data?.response[0]?.subscriptiondetails)?.length > 0
              ) {
                dispatch(
                  changeTransactionValues(
                    "promoter",
                    data.response[0].subscriptiondetails.promoter === "true"
                      ? true
                      : false
                  )
                );
                dispatch(
                  changeTransactionValues(
                    "venue",
                    data.response[0].subscriptiondetails.venue === "true"
                      ? true
                      : false
                  )
                );
                dispatch(
                  changeTransactionValues(
                    "sistic",
                    data.response[0].subscriptiondetails.sistic === "true"
                      ? true
                      : false
                  )
                );
              }
            }
          }
        })
        .catch((err) => {
          var tokenExiration = ifTokenExpire(err);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(changeTransactionValues("newsletterDetails", []));
          }
        });
    }
  };
};
