import React from "react";
import { Shell, Button } from "@StixNext/mojito-ui";
import SwfPdf from "../assets/pdf/swf.pdf";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
const Schedule = () => {
  return (
    <Shell.Body>
      <div className={"pdf-view"}>
        <div className={"pdf-view"}>
          <Grid>
            <Row>
              <Column className={"pdf-download"} size={12}>
                <Button
                  primary
                  onClick={() => {
                    window.open(SwfPdf, "_blank");
                  }}
                >
                  Download SWF 2020 Schedule PDF
                </Button>
              </Column>
            </Row>
          </Grid>

          <iframe
            title="SWF Schedule PDF"
            src={SwfPdf}
            frameBorder="0"
            height={"884"}
            width={"884"}
          ></iframe>
        </div>
      </div>
    </Shell.Body>
  );
};

export default Schedule;
