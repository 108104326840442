import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Shell,
  Stage,
  DirectorThought,
  FeatureArtists,
  Video,
  Text,
  Button,
  DropInPayment,
  BouncerContextHook,
} from "@StixNext/mojito-ui";

import loading from "../assets/images/loading.gif";

import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";

function HiddenTransaction(props) {
  const value = BouncerContextHook();

  useEffect(() => {
    // props.changeTransactionValues("transactionStatus", 11);
    // props.changeTransactionValues("isOpen", true);
    if (localStorage.getItem("grouppass") == "true") {
      props.changeTransactionValues("hideBuyButton", true);
      if (localStorage.getItem("logout") == "true") {
        props.changeTransactionValues("transactionStatus", 5);
        props.changeTransactionValues("isOpen", true);
      }
      localStorage.setItem("grouppass", false);
    }
    localStorage.setItem("grouppasscompleted", false);
  }, []);
  const [braintreeError, setBraintreeError] = useState({});
  const hanldePayment = (cardPayload) => {
    console.log("card", cardPayload);
    let subscription = {
      promoter: props.transactionDetails.promoter,
      venue: props.transactionDetails.venue,
      sistic: props.transactionDetails.sistic,
    };
    props.checkoutTransaction(
      cardPayload,
      props.loginDetails.user,
      {
        _id: "612d9e7022664700122e1790",
        id: "SL-PD-2021-SP-FWL-TSMN-LTTL",
        title: "A Farewell to Tasmin Little",
        description: "Single Access Pass",
        expiresOn: "2022-10-23T15:59:00.000Z",
        isActive: "true",
        isSeasonPass: "false",
        startSalesDate: "2021-08-31T03:00:00.000Z",
        price: "1",
        sessionIds: "farewell-to-tasmin-little",
        validityInMonths: "1",
        isFixedExpiryDate: "true",
        isFreeEvent: "false",
        playbackBundle: "",
        isFixedExpirtyDate: "true",
        videoIds: "FP1",
        tenant: "SSOLOUNGE",
        collection_id: "612d99c622664700122e178e",
        collection: "products",
        isPublished: "true",
      },
      subscription,
      true
    );
  };
  useEffect(() => {
    if (!props.loginDetails.authToken && props.loginDetails.accessToken) {
      props.getMerchantAuthToken(1);
    }
  }, [props.loginDetails]);
  const transactionError = (err) => {
    setBraintreeError(err);
  };
  const threeDSecureConfirmation = (payload) => {
    console.log("three d options", payload);
  };
  return (
    <Shell.Body className="shell_body overview_bdy">
      <div className={"alignment"}>
        <>
          <div
            className="loader-pro"
            style={{
              width: "100%",
              display: props.transactionDetails.loader,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <img
                className="img-fluid"
                style={{ width: "190px", height: "auto" }}
                src={loading}
                alt="Avatar"
              />
            </div>
          </div>
          <div
            className="dropPayment"
            style={{
              width: "100%",
              display: props.transactionDetails.paymentLoader,
            }}
          ></div>
        </>
        {props.transactionDetails?.amountbcmetadata &&
          Object.keys(props.transactionDetails?.amountbcmetadata).length > 0 &&
          props.transactionDetails?.amountbcmetadata?.totalamount && (
            <DropInPayment
              authorizationToken={props.loginDetails.authToken}
              handlePaymentMethod={hanldePayment}
              onError={transactionError}
              buttonText="Proceed to Payment"
              transactionFailed={
                props.transactionDetails.transactionSuccessFlag
              }
              classProp="dropPayment"
              showConsent={true}
              checkedCheckbox={props.transactionDetails.checkconsent}
              amount={props.transactionDetails.amountbcmetadata.totalamount}
              isThreeDSecure={true}
              threeDSecureConfirmation={threeDSecureConfirmation}
              userInfo={props.loginDetails.user}
              loaderFunction={() => {
                props.changeTransactionValues("loader", "none");
                props.changeTransactionValues("paymentLoader", "block");
              }}
              onChange={(e) => {
                props.changeTransactionValues("checkconsent", e.target.checked);
              }}
            />
          )}
      </div>
    </Shell.Body>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getMerchantAuthToken: (amount) =>
    dispatch(actions.getMerchantAuthToken(amount)),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (
    cardPayload,
    user,
    product,
    Subscription,
    isThreeDSecure
  ) =>
    dispatch(
      transActions.checkoutTransaction(
        cardPayload,
        user,
        product,
        Subscription,
        isThreeDSecure
      )
    ),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HiddenTransaction);
