import { database } from ".";

export const db = database;

// if (process.env.NODE_ENV === "development") {
//   db.useEmulator("localhost", 9000);
// }

export const getSessionMessages = db.ref(
  `/${process.env.REACT_APP_CLIENTID}/${process.env.REACT_APP_NODE_ENV}/messages`
);

export const getSessionReactions = db.ref(
  `/${process.env.REACT_APP_CLIENTID}/${process.env.REACT_APP_NODE_ENV}/reactions`
);

export const getSessionDetails = (userId) =>
  db.ref(
    `/${process.env.REACT_APP_CLIENTID}/${process.env.REACT_APP_NODE_ENV}/sessions/` +
      userId
  );

export const getVideoReactions = (videoId) =>
  db.ref(
    `/${process.env.REACT_APP_CLIENTID}/${process.env.REACT_APP_NODE_ENV}/reactions/` +
      videoId
  );

export const getUserSession = db.ref(
  `/${process.env.REACT_APP_CLIENTID}/${process.env.REACT_APP_NODE_ENV}/usersession`
);

export const getSessionNickname = db.ref(
  `/${process.env.REACT_APP_CLIENTID}/${process.env.REACT_APP_NODE_ENV}/nicknames`
);
