import { getAbout } from "./commonapi/mockData";

export const changeAboutValues = (field, value) => {
  return {
    type: "UPDATE_ABOUT",
    field,
    value,
  };
};

export const fetchVenue = () => {
  return (dispatch) => {
    getAbout()
      .then((data) => {
        dispatch(changeAboutValues("AboutSso", data));
      })
      .catch((err) => {});
  };
};
 
