const init = {
  userActivities: [],
  userActivityDetail: {},
  isReload: false,
};

export const UserActivities = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_USERACTIVITY_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
