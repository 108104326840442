import { api } from "./commonapi/apiPaths";
import { changeTransactionValues } from "./Transaction";
import moment from "moment";

export const changeProductValues = (field, value) => {
  return {
    type: "UPDATE_PRODUCT_VALUE",
    field,
    value,
  };
};

// export const fetchProducts = (programmeId) => {
//   return (dispatch) => {
//     return fetch(api.products, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//       method: "POST",
//       body: JSON.stringify({
//         clientid: process.env.REACT_APP_CLIENTID,
//         sessionid: programmeId,
//       }),
//     })
//       .then((res) => {
//         return res.json();
//       })
//       .then((data) => {
//         dispatch(changeProductValues("videoDetailId", programmeId));
//         dispatch(changeProductValues("productList", data.product));
//         dispatch(changeProductValues("productLoading", false));
//       })
//       .catch((err) => {});
//   };
// };

export const fetchProducts = (programmeDetails, passType, freeEventClciked) => {
  const { videoDetailId, isBundleEvent } = programmeDetails;
  return (dispatch) => {
    return fetch(`${api.fetchCmsProducts}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
      // body: JSON.stringify({
      //   clientid: process.env.REACT_APP_CLIENTID,
      //   sessionid: programmeId,
      // }),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        let result = await data.data.filter((item) => {
          var currentDate = moment(new Date(moment.utc().format()));
          var startSaleDate = moment(
            new Date(moment.utc(item.startSalesDate).format())
          );
          var endSaleDate = moment(
            new Date(moment.utc(item.endSalesDate).format())
          );
          return (
            startSaleDate.diff(currentDate, "seconds") < 1 &&
            endSaleDate.diff(currentDate, "seconds") > -1
          );
        });
        if (passType === "seasonPass") {
          dispatch(
            changeProductValues(
              "productList",
              result.filter(
                (item) =>
                  item.isSeasonPass &&
                  item.price > 0 &&
                  !item.isSubscribableProduct &&
                  ((item.isFreeEvent && item.isFreeEvent === false) ||
                    !item.isFreeEvent)
              )
            )
          );
        } else if (passType === "seasonFreePass") {
          dispatch(
            changeProductValues(
              "productList",
              result.filter(
                (item) =>
                  item.isSeasonPass &&
                  item.isFreeEvent &&
                  item.isFreeEvent === true
              )
            )
          );
        } else if (passType === "singlePass") {
          let singlePassTickets = result.filter((item) => !item.isSeasonPass);
          singlePassTickets = singlePassTickets.filter((single) => {
            return (
              !single.generateDedicatedLink &&
              single.sessionIds.split(",").includes(videoDetailId)
            );
          });
          if (isBundleEvent && !freeEventClciked) {
            singlePassTickets = singlePassTickets.filter(
              (item) => item.sessionIds.split(",").length > 1
            );
          } else if (!freeEventClciked) {
            singlePassTickets = singlePassTickets.filter(
              (item) => item.sessionIds.split(",").length === 1
            );
          }
          dispatch(changeProductValues("productList", singlePassTickets));
        } else {
          dispatch(changeProductValues("productList", result));
        }

        dispatch(changeProductValues("productLoading", false));
      })
      .catch((err) => {});
  };
};

export const fetchCheckProducts = (programmeId, passType, freeEventClciked) => {
  return (dispatch) => {
    return fetch(`${api.fetchCmsProducts}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
      // body: JSON.stringify({
      //   clientid: process.env.REACT_APP_CLIENTID,
      //   sessionid: programmeId,
      // }),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        // data = {
        //   total: 2,
        //   limit: 100000,
        //   skip: 0,
        //   data: [
        //     {
        //       _id: "612d9c3622664700122e178f",
        //       id: "SL-PD1-2021-PASS",
        //       title: "Exceptional 2021 Annual Access Pass",
        //       description:
        //         "Exceptional 2021-2022 Season with incredible videos",
        //       expiresOn: "2021-09-30T16:00:00.000Z",
        //       isActive: true,
        //       isSeasonPass: true,
        //       startSalesDate: "2021-08-31T03:00:00.000Z",
        //       price: 70,
        //       validityInMonths: "12",
        //       tenant: "SSOLOUNGE",
        //       collection_id: "612d99c622664700122e178e",
        //       collection: "products",
        //     },
        //     {
        //       _id: "612d9e7022664700122e1790",
        //       id: "SL-ALACARTE-FP1",
        //       title: "Feature Programme 01",
        //       description: "First of the 10 part production 2021 Fall Season",
        //       expiresOn: "2021-09-03T04:59:00.000Z",
        //       isActive: true,
        //       startSalesDate: "2021-08-31T03:00:00.000Z",
        //       price: 16,
        //       sessionIds: "FP1",
        //       isFixedExpirtyDate: true,
        //       validityInMonths: "12",
        //       videoIds: "FP1",
        //       tenant: "SSOLOUNGE",
        //       collection_id: "612d99c622664700122e178e",
        //       collection: "products",
        //     },
        //   ],
        // };
        let result = await data.data.filter((item) => {
          var currentDate = moment(new Date(moment.utc().format()));
          var startSaleDate = moment(
            new Date(moment.utc(item.startSalesDate).format())
          );
          var endSaleDate = moment(
            new Date(moment.utc(item.endSalesDate).format())
          );
          return (
            startSaleDate.diff(currentDate, "seconds") < 1 &&
            endSaleDate.diff(currentDate, "seconds") > -1
          );
        });
        if (passType === "singlePass") {
          let singlePassTickets = [];
          singlePassTickets = result.filter(
            (item) =>
              !item.isSeasonPass &&
              ((freeEventClciked &&
                item.isFreeEvent &&
                item.isFreeEvent === true) ||
                (!freeEventClciked &&
                  item.isFreeEvent &&
                  item.isFreeEvent === false) ||
                (!freeEventClciked && !item.isFreeEvent))
          );
          singlePassTickets = singlePassTickets.filter((single) => {
            return (
              !single.generateDedicatedLink &&
              single.sessionIds.split(",").includes(programmeId)
            );
          });
          let singlePassList = singlePassTickets.filter((item) => {
            return item.sessionIds.split(",").length === 1;
          });
          let bundlePassList = singlePassTickets.filter((item) => {
            return item.sessionIds.split(",").length > 1;
          });
          dispatch(changeProductValues("singlePassList", singlePassList));
          dispatch(changeProductValues("bundlePassList", bundlePassList));
          dispatch(changeProductValues("checkProductList", singlePassTickets));
        } else {
          dispatch(changeProductValues("checkProductList", result));
        }

        // dispatch(changeProductValues("productLoading", false));
      })
      .catch((err) => {});
  };
};

export const fetchSubscribableProducts = () => {
  return (dispatch) => {
    return fetch(api.fetchSubscribableProducts)
      .then((res) => res.json())
      .then(async ({ total, data }) => {
        let result = await data.filter((item) => {
          var currentDate = moment(new Date(moment.utc().format()));
          var startSaleDate = moment(
            new Date(moment.utc(item.startSalesDate).format())
          );
          var endSaleDate = moment(
            new Date(moment.utc(item.endSalesDate).format())
          );
          return (
            startSaleDate.diff(currentDate, "seconds") < 1 &&
            endSaleDate.diff(currentDate, "seconds") > -1
          );
        });
        if (total > 0)
          dispatch(changeProductValues("subscriableProductList", result));
      })
      .catch((err) => {});
  };
};

export const discountPasscodeValidation = (productid, passcode) => {
  return (dispatch) => {
    return fetch(api.passcodeValidationn, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        clientid: process.env.REACT_APP_CLIENTID,
        productid,
        passcode,
        isDiscountPassRedemption: localStorage.getItem(
          "discountPassRedemption"
        ),
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let discountPassRedemption = localStorage.getItem(
          "discountPassRedemption"
        );
        if (data?.message === "Valid Passcode") {
          dispatch(changeProductValues("isValidPasscode", true));
          dispatch(
            changeProductValues("passcodeValidationMessage", data?.message)
          );
          if (discountPassRedemption === "true") {
            dispatch(changeTransactionValues("loader", "block"));
            dispatch(changeTransactionValues("paymentLoader", "none"));
            dispatch(changeTransactionValues("showDropinComponent", true));
            dispatch(changeTransactionValues("transactionStatus", 2));
            dispatch(changeTransactionValues("isOpen", true));
          }
        } else {
          dispatch(changeProductValues("isValidPasscode", false));
          dispatch(
            changeProductValues("passcodeValidationMessage", data?.message)
          );
          if (discountPassRedemption === "true") {
            dispatch(changeTransactionValues("transactionStatus", 14));
            dispatch(changeTransactionValues("isOpen", true));
          }
        }
      })
      .catch((err) => {
        let discountPassRedemption = localStorage.getItem(
          "discountPassRedemption"
        );
        dispatch(changeProductValues("isValidPasscode", false));
        dispatch(
          changeProductValues("passcodeValidationMessage", err?.message)
        );
        if (discountPassRedemption === "true") {
          dispatch(changeTransactionValues("transactionStatus", 4));
          dispatch(changeTransactionValues("isOpen", true));
        }
      });
  };
};
