import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Text, Button } from "@StixNext/mojito-ui";

import failed from "../../assets/images/failed.png";
import { theme } from "./../../Theme";

import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";

function GroupPassFailure(props) {
  return (
    <>
      <Grid>
        <Row>
          <Column
            xl={5}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={failed}
              alt="icon"
              style={{ width: "166px", height: "166px", opacity: "0.19" }}
            />
          </Column>
          <Column xl={7} xs={12}>
            <Row>
              <Column xl={12}>
                <Text as="p">
                  <Text as="h6" className="fail_text">
                    Redemption attempt failed!!!
                  </Text>
                </Text>
                <Text as="p">
                  <Text as="h6" className="failed_txt">
                    {props.transactionDetails.gpurchaseFailureMessage}
                    {/* Please try again with another access link */}
                    {/* or{" "}
                    {
                      <a
                        onClick={() => {
                          props.changeTransactionValues("isOpen", true);
                          props.changeTransactionValues("transactionStatus", 9);
                          props.changeTransactionValues(
                            "showDropinComponent",
                            true
                          );
                        }}
                        href="#"
                      >
                        click here{" "}
                      </a>
                    }{" "}
                    to purcase a festival pass. */}
                  </Text>
                </Text>
              </Column>
            </Row>
          </Column>
        </Row>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupPassFailure);
